// BG
export const locale = {
  GENERAL: {
    APP_TITLE: "Besta Pharmaceuticals"
  },
  ERRORS: {
    DEFAULT: {
      MESSAGE: "Нещо се обърка",
      DESCRIPTION: "Моля презаредете страницата и опитайте отново"
    }
  },
  CONFIRM: {
    DELETE: {
      MESSAGE: "Сигурни ли сте, че искате да изтриете този запис?",
      DESCRIPTION: "Tова дейтсвие не може да бъде отменено!"
    }
  },
  TRANSLATOR: {
    SELECT: "Избери своя език"
  },
  MENU: {
    HOME: "Начало",
    NEW: "Нов",
    ACTIONS: "Дейсвтия",
    PAGES: "Страници",
    DASHBOARD: "Начало",
    TABLE: "Таблица",
    ADD: "Добави"
  },
  A: {
    PER_PAGE: "Резултати на страница",
    LOADING: "Зареждане...",
    RESULTS: "резултата",
    SEARCH: "Търсене",
    NO_DATA: "Няма данни"
  },
  BUTTON: {
    NEW: "Нов",
    NEWO: "Ново",
    NEWA: "Новa",
    ADD_REQUIREMENT: "Добави ново изискване",
    ADD_RECIPE: "Добави нова рецепта",
    ADD_HALL: "Добави нова зала",
    ADD_EMAIL: "Добави нов имейл",
    ADD_DESTRUCTION_PROTOCOL: "Добави нов протокол за унищожаване",
    SEND: "Изпрати",
    SAVE: "Запази",
    READY: "Готово",
    ADD: "Добави",
    DELETE: "Изтрий",
    FILES: "Файлове",
    EXPORT: "Експорт",
    ADD_HOLIDAY: "Добави почивен ден",
    CONFIRM: "Потвърди",
    CANCEL: "Откажи"
  },
  COMPONENTS: {
    PROFILE: "Профил",
    HALLS: "Зали",
    EMAIL: "Имейли",
    DESTRUCTION_PROTOCOL:
      "Протокол за унищожаване на вторични опаковъчни материали",
    PAGINATION_FROM: "от",
    PRODUCT: "Продукт",
    WORK_BREAK: "Почивка",
    RAW_PRODUCT: "Суровина",
    VENDOR: "Производител",
    WAREHOUSE: "Склад",
    MATERIAL: "Материал",
    MATERIAL_PROPERTY: "Изискване за материали",
    USER: "Потребител",
    OPERATOR: "Оператор",
    CLIENT: "Клиенти",
    ACCEPTANCEACT: "Приемно-предавателен протокол",
    MATERIALREQUEST: "Заявка за лекарствен продукт",
    CERTIFICATE: "Сертификат за освобождаване на опаковъчни материали",
    ROUTEMAP: "Mаршрутна карта",
    PRINTING_HOUSE: "Печатница",
    OPERATOR_LOGIN: "Вход за оператори"
  },
  AUTH: {
    GENERAL: {
      FOOTER_LEFT: "Besta Pharmaceuticals",
      SIGNUP_BUTTON: "Вход",
      BACK_BUTTON: "Назад",
      PRIVACY: "Политика за поверителност"
    },
    LOGIN: {
      TITLE: "Вход",
      VALID_EMAIL: "Въведете валиден имейл.",
      VALID_PASSWORD: "Паролата е задължителна.",
      VALID_PASSWORD_CONFIRM: "Паролите не съвпадат.",
      BUTTON: "Вход",
      TWO_FACTOR:
        "За да продължите, въведете 6 цифрения код изпратен на вашия имейл!",
      CODE: "Код",
      VALID_CODE: "Въведете валиден код."
    },
    FORGOT: {
      TITLE: "Забравена парола?",
      SUCCESS: "Ще получите имейл с линк за рестартиране на паролата си.",
      DESC: "Въведете вашия имейл, за да рестартирате паролата си!"
    },
    RESET: {
      TITLE: "Въведете вашата нова парола",
      DESC: "Въведете вашия имейл, за да рестартирате паролата си!",
      SUCCESS: "Вашата парола беше успешно рестартирана."
    },
    REGISTER: {
      TITLE: "Регистрация",
      DESC: "Въведете вашите данни, за да създадете акаунт",
      SUCCESS: "Вашият акаунт е създаден успешно."
    },
    INPUT: {
      EMAIL: "Имейл",
      FULLNAME: "Имена",
      PASSWORD: "Парола",
      CONFIRM_PASSWORD: "Потвърдете парола",
      USERNAME: "Потребителско име"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  DATA: {
    HI: "Здравей",
    EXIT: "Изход",
    MY_PROFILE: "Моят профил",
    PROFILE_PROPERTIES: "Настройки на акаунта",
    CHANGE_YOUR_PASSWORD: "Моля, променете паролата си"
  },
  sealCode: "Код на мениджър",
  LABELS: {
    acceptanceActDetail: {
      expirationDate: "Срок на годност",
      batch: "Партиден номер",
      acceptanceAct: {
        client: {
          name: "Възложител"
        }
      }
    },
    documentType: "Вид на документа",
    routeMap: {
      certificate: {
        id: "id",
        signedAt: "Дата",
        number: "Номер на сертификат"
      },
      certificateNumber: {
        number: "Номер на сертификат"
      },
      id: "id",
      finishDate: "Дата на създаване",
      product: {
        name: "Име и концентрация на ЛП"
      }
    },
    materialName: "Материал",
    login: {
      email: "Имейл"
    },
    client: {
      name: "Клиент"
    },
    LOG_BTN: "Лог",
    sealCode: "Код на мениджър",
    createdAt: "Създаден на",
    address: "Адрес",
    id: "ID",
    name: "Име",
    email: "Email",
    values: "Стойности",
    role: "Роля",
    strength: "Концентрация",
    "raw-product": {
      name: "Име",
      strength: "Концентрация",
      ma_holder: "Притежател на разрешение за употреба",
      primary_q_i_b: "Първични опаковки в кутия",
      vendor: {
        name: "Име"
      },
      formType: {
        name: "Лекарствена форма"
      },
      note: "Бележка"
    },
    "destruction-packaging-material": {
      signed_by_qualified: "Подписан",
      client: {
        name: "Възложител"
      },
      quantity: "Количество",
      material: {
        name: "Материал",
        type: {
          name: "Тип"
        }
      },
      reason: "Причина"
    },
    "prepared-product": {
      name: "Име",
      primary_q_i_b: "Първични опаковки в кутия",
      note: "Забележка",
      quantity_in_medical_package:
        "Брой лекарствени единици в първична опаковка",
      price_under: "Продажна цена до 5000 бройки",
      price_over: "Продажна цена над 5000 бройки",
      q_i_b: "Брой кутии в кашон",
      quantity: "Количество",
      norm: "Норматив",
      typeable: {
        name: "Суровина"
      },
      product: {
        name: "Име на продука"
      },
      formula: {
        name: "Име"
      },
      client: {
        name: "Клиент"
      }
    },
    qIB: "Кутии в кашон",
    primaryQIB: "Първични опаковки в кутия",
    primaryQuantity: "Първични опаковки",
    priceUnder: "Продажна цена до 5000 бройки",
    priceOver: "Продажна цена над 5000 бройки",
    primary_qIB: "Първични опаковки в кутия",
    primary_quantity: "Първични опаковки",
    price_under: "Продажна цена до 5000 бройки",
    price_over: "Продажна цена над 5000 бройки",
    mol: "Мол",
    eik: "ЕИК",
    firstName: "Име",
    lastName: "Фамилия",
    active: "Активен",
    number: "Код",
    date: "Дата",
    status_id: "status_id",
    start_date: "Начална дата",
    finish_date: "Дата на приключване",
    startDate: "Начална дата",
    finishDate: "Дата на приключване",
    endDate: "Крайна дата",
    quantity: "Брой",
    halls: "Зала",
    batch: "Партида",
    product_raw_quantity: "Бройка суровина",
    product_quantity: "Бройка",
    instruction_id: "Инструкция",
    user_id: "потр. ID",
    product_formula_id: "формула id",
    materialDate: "Дата на зареждане",
    material_date: "Дата на зареждане",
    remainQuantity: "Останали бройки",
    readyQuantity: "Готови бройки",
    remain_quantity: "Останали бройки",
    ready_quantity: "Готови бройки",
    operator: "Оператор",
    completedNorm: "Изпълнена норма",
    year: "Година",
    month: "Месец",
    monthAverage: "Средно месечно",
    norm: "Норма",
    made: "Изработено",
    result: "Резултат",
    from: "От",
    to: "До",
    startTime: "Начало",
    endTime: "Край",
    holidaysFor: "Почивни дни за",
    operations: "Операции",
    productManager: {
      name: "Мениджър"
    },
    status: {
      name: "Статус"
    },
    type: {
      name: "Вид"
    },
    product: {
      name: "Продукт"
    },

    material: {
      name: "Материал",
      note: "Бележка",
      type: {
        name: "Вид"
      },
      client: {
        name: "Клиент"
      },
      product: {
        name: "Име"
      },
      property: {
        name: "Изискване"
      }
    },
    invoice: {
      user: {
        name: "Потребител"
      },
      quantity: "Количество",
      price: "Цена",
      paymentType: {
        name: "Начин на плащане"
      },
      number: "Номер",
      status: {
        name: "Статус"
      },
      date: "Дата"
    },
    nextControl: "Следващ контрол",
    next_control: "Следващ контрол",
    "material-details-property": {
      name: "Име",
      property: {
        name: "Име"
      }
    },
    warehouse: {
      name: "Име"
    },
    vendor: {
      name: "Име",
      address: "Адрес"
    },
    printery: {
      name: "Име",
      address: "Адрес"
    },
    clients: {
      name: "Име",
      eik: "ЕИК",
      mol: "Мол",
      vat: "Номер по ДДС",
      bank: "Банка",
      iban: "IBAN",
      contact: "Контакт",
      country: "Държава",
      city: "Град",
      address: "Адрес"
    },
    "material-request": {
      client: {
        name: "Клиент"
      },
      country: "Държава",
      date: "Дата",
      fromWarehouse: {
        name: "От склад"
      },
      toWarehouse: {
        name: "Към склад"
      },
      rawProduct: {
        name: "Суровина"
      },
      batch: "Партида",
      buy: "Срок на годност",
      quantity: "Количество",
      number: "Номер"
    },
    operators: {
      first_name: "Име",
      last_name: "Фамилия",
      seal_code: "Код за подписване",
      code: "Код",
      active: "Активен"
    },
    certificate: {
      signed_by_qualified: "Подписан",
      certificateType: {
        name: "Тип"
      },
      material: {
        name: "Материал"
      },
      batch: "Партиден номер",
      vendor: {
        name: "Печатница"
      },
      vendor_batch: "Партиден номер на производител",
      quantity: "Количество",
      user: {
        name: "Потребител"
      },
      note: "Коментар",
      status: {
        name: "Статус"
      },
      delivery_date: "Дата на доставка",
      date: "Дата",
      conclusion: "Заключение",
      answer: "Отговаря",
      property: {
        name: "Показател"
      },
      value: "Изисквания",
      number: "Код",
      client: {
        name: "Клиент"
      }
    },
    user: {
      name: "Име",
      email: "Имейл",
      seal_code: "Код за подпис"
    },
    "route-map": {
      description: "Описание",
      user: {
        name: "Потребител"
      },
      comment_before_pause: "Коментар преди паузата",
      comment_after_start: "Коментар след паузата",
      scrap: "Брак бр.",
      returned: "Върнати бр.",
      package: "Външен вид, цвятцялост на опаковката",
      instructions: "Съответствие на продукта с инструкциите за преопаковане",
      batch: "Четливост и достоверност на партиден № и срок на годност",
      notes: "Бележки",
      nextControl: "Следващ контрол",
      next_control: "Следващ контрол",
      name: "изходни суровини / опаковъчни материали",
      status: {
        name: "Статус"
      },
      operator: {
        name: "Име"
      },
      start_date: "Начална дата",
      finish_date: "Приключена на",
      product: {
        name: "Продукти"
      },
      quantity: "Количество",
      productManager: {
        name: "Мениджър"
      },
      product_raw_quantity: "Количество суровини",
      product_quantity: "Количество суровини",
      material_date: "Дата и час на зареждане"
    }
  },
  LABEL_LOGS: {
    emails: {
      email: "Имейл"
    },
    login: {
      email: "Имейл"
    },
    client: {
      name: "Клиент"
    },
    strength: "Концентрация",
    "raw-product": {
      name: "Име",
      strength: "Концентрация",
      ma_holder: "Притежател на разрешение за употреба",
      primary_q_i_b: "Първични опаковки в кутия",
      vendor: {
        name: "Име"
      },
      formType: {
        name: "Лекарствена форма"
      },
      note: "Бележка"
    },
    "work-breaks": {
      start_time: "Начало",
      end_time: "Край"
    },
    "destruction-packaging-material": {
      signed_by_qualified: "Подписан",
      certificate: {
        number: "Код"
      },
      client: {
        name: "Възложител"
      },
      quantity: "Количество",
      material: {
        name: "Материал",
        type: {
          name: "Тип"
        }
      },
      reason: "Причина"
    },
    "prepared-product": {
      name: "Име",
      primary_q_i_b: "Първични опаковки в кутия",
      note: "Забележка",
      quantity_in_medical_package:
        "Брой лекарствени единици в първична опаковка",
      price_under: "Продажна цена до 5000 бройки",
      price_over: "Продажна цена над 5000 бройки",
      q_i_b: "Брой кутии в кашон",
      quantity: "Количество",
      norm: "Норматив",
      typeable: {
        name: "Суровина"
      },
      product: {
        name: "Име на продука"
      },
      formula: {
        name: "Име"
      },
      client: {
        name: "Клиент"
      }
    },
    productManager: {
      name: "Мениджър"
    },
    status: {
      name: "Статус"
    },
    type: {
      name: "Вид"
    },
    product: {
      name: "Продукт"
    },
    material: {
      name: "Материал",
      note: "Бележка",
      type: {
        name: "Вид"
      },
      client: {
        name: "Клиент"
      },
      product: {
        name: "Име"
      },
      property: {
        name: "Изискване"
      }
    },
    invoice: {
      user: {
        name: "Потребител"
      },
      quantity: "Количество",
      price: "Цена",
      paymentType: {
        name: "Начин на плащане"
      },
      number: "Номер",
      status: {
        name: "Статус"
      },
      date: "Дата"
    },
    "material-details-property": {
      name: "Име",
      property: {
        name: "Име"
      }
    },
    warehouse: {
      name: "Име"
    },
    vendor: {
      name: "Име",
      address: "Адрес"
    },
    printery: {
      name: "Име",
      address: "Адрес"
    },
    clients: {
      name: "Име",
      eik: "ЕИК",
      mol: "Мол",
      vat: "Номер по ДДС",
      bank: "Банка",
      iban: "IBAN",
      contact: "Контакт",
      country: "Държава",
      city: "Град",
      address: "Адрес"
    },
    "material-request": {
      client: {
        name: "Клиент"
      },
      country: "Държава",
      date: "Дата",
      fromWarehouse: {
        name: "От склад"
      },
      toWarehouse: {
        name: "Към склад"
      },
      rawProduct: {
        name: "Суровина"
      },
      batch: "Партида",
      buy: "Срок на годност",
      quantity: "Количество",
      number: "Номер",
      status: "Статус",
      number_primary_units: "Брой първични единици",
      packages: "Брой опаковки"
    },
    operators: {
      first_name: "Име",
      last_name: "Фамилия",
      seal_code: "Код за подписване",
      code: "Код",
      active: "Активен"
    },
    certificate: {
      conclusion_protocol: "Заключение (Протокол)",
      signed_by_qualified: "Подписан",
      certificateType: {
        name: "Тип"
      },
      material: {
        name: "Материал"
      },
      batch: "Партиден номер",
      vendor: {
        name: "Печатница"
      },
      vendor_batch: "Партиден номер на производител",
      quantity: "Количество",
      user: {
        name: "Потребител"
      },
      note: "Коментар",
      status: {
        name: "Статус"
      },
      delivery_date: "Дата на доставка",
      date: "Дата",
      conclusion: "Заключение (Сертификат)",
      answer: "Отговаря",
      property: {
        name: "Показател"
      },
      value: "Изисквания",
      number: "Код",
      client: {
        name: "Клиент"
      }
    },
    user: {
      name: "Име",
      email: "Имейл",
      seal_code: "Код за подпис",
      role: {
        name: "Роля"
      }
    },
    "route-map": {
      vendor: {
        name: "Производител"
      },
      reason: "Причина",
      authorization_holder: "Притежател на разрешение за употреба",
      product_batch: "Партида",
      archive_note: "Бележка",
      sample: "Мостра",
      warehouse_has_sample:
        "В склада за музейни мостри вече съществува мостра за този партиден номер.",
      description: "Описание",
      date: "Дата",
      signed: "Подписана",
      signed_at: "Подписана на",
      is_sent_to_labourer: "Изпратена на общ работник",
      user: {
        name: "Потребител"
      },
      finishedBy: {
        name: "Приключил маршрутната карта"
      },
      operation: {
        name: "Име на операция"
      },
      comment_before_pause: "Коментар преди паузата",
      comment_after_start: "Коментар след паузата",
      scrap: "Брак бр.",
      returned: "Върнати бр.",
      package: "Външен вид, цвятцялост на опаковката",
      instructions: "Съответствие на продукта с инструкциите за преопаковане",
      batch: "Четливост и достоверност на партиден № и срок на годност",
      notes: "Бележки",
      nextControl: "Следващ контрол",
      next_control: "Следващ контрол",
      name: "изходни суровини / опаковъчни материали",
      status: {
        name: "Статус"
      },
      operator: {
        name: "Име",
        first_name: "Оператор име",
        last_name: "Оператор фамилия"
      },
      start_date: "Начална дата",
      finish_date: "Приключена на",
      product: {
        name: "Продукти"
      },
      quantity: "Количество",
      productManager: {
        name: "Мениджър"
      },
      product_raw_quantity: "Количество суровини",
      product_quantity: "Количество суровини",
      material_date: "Дата и час на зареждане"
    },
    halls: {
      name: "Име",
      login: {
        email: "Имейл"
      }
    },
    1: "Да",
    0: "Не",
    true: "Да",
    false: "Не"
  },
  LOGS: {
    break_finished: "Край на почивката",
    break_added: "Начало на почивка",
    updated: "Редактиран/а",
    break_interrupted: "Почивката е прекъсната",
    product_scrap_added: "Брак на суровини",
    material_scrap_added: "Брак на материали",
    operator_logout: "Оператор приключи работа",
    operator_login: "Оператор се чекира",
    operation_create: "Извършена операция",
    cost_standard_updated: "Обновен разходен норматив",
    control_successful: "Успешен контрол",
    charged: "Залата е заредена",
    cleaned: "Залата е почистена",
    created: "Създаден/а",
    route_map_operation_created: "Извършена операция",
    created_user_role: "Потребителят е свързан към нова роля",
    deleted_user_role: "Потребителят е премахнат от роля",
    route_map_operation_deleted: "Операцията беше изтрита",
    control_failed: "Неуспешен контрол",
    product_formula_was_created: "Добавена е нова рецепта",
    product_formula_material_was_deleted: "Инструкцията беше изтрита",
    product_operation_was_created: "Добавена е нова операция",
    product_operation_was_deleted: "Операцията беше изтрита",
    product_manufacturer_was_created: "Добавен е производител",
    route_map_blocking: "Маршрутната карта е блокирана",
    product_manufacturer_was_deleted: "Производителят е премахнат",
    material_product_map_was_created: "Добавен е продукт",
    material_detail_was_created: "Добавено е ново изискване",
    material_detail_was_deleted: "Изискването беше изтрито",
    deleted: "Изтрит",
    material_detail_property_value_was_created: "Нова стойност",
    material_detail_property_value_was_deleted: "Стойността беше изтрита",
    material_request_detail_was_created: "Добавен е нов продукт",
    material_request_detail_was_updated: "Продуктът беше редактиран",
    material_request_detail_was_deleted: "Продуктът беше изтрит",
    acceptance_act_was_created: "Създаден е ППП",
    certificate_result_was_created: "Добавен е показател",
    certificate_result_was_updated: "Променен е показател",
    destruction_protocol_product_was_created: "Продуктът беше добавен",
    destruction_protocol_product_was_updated: "Продуктът беше редактиран",
    destruction_protocol_product_was_deleted: "Продуктът беше изтрит",
    destruction_protocol_was_signed: "Протоколът беше подписан",
    invoice_detail_deleted: "Продуктът беше изтрит",
    invoice_detail_updated: "Продуктът беше променен",
    invoice_detail_created: "Добавен е продукт",
    certificate_signed_by_qualified: "Подписан",
    route_map_updated_by_admin: "Редактирана от администратор",
    route_map_updated_by_admin_printout: "Редактирана от администратор",
    route_map_updated_printout: "Редактирана",
    cost_standard_updated_by_admin:
      "Разходния норматив беше променен от администратор",
    cost_standard_updated_by_manager:
      "Разходния норматив беше променен от мениджър",
    route_map_operation_updated: "Операцията беше редактирана",
    product_formula_material_was_created:
      "Добавена е нова инструкция към рецепта",
    update_batch: "Партидата беше променена"
  },
  PAGES: {
    NORMS: {
      heading: "Норми"
    },
    OPERATORS_HOLIDAYS: {
      breadcrumb: "Почивни дни за оператори",
      formAddHolidayHeading: "Добави почивен ден",
      formEditHolidayHeading: "Редакция на почивен ден"
    }
  },
  VALIDATIONS: {
    REQUIRED: "Полето е задължително",
    TO_DATE_AFTER:
      "Крайната дата трябва да е по-голяма или равна на началната дата"
  },
  MONTHS: {
    1: "Януари",
    2: "Февруари",
    3: "Март",
    4: "Април",
    5: "Май",
    6: "Юни",
    7: "Юли",
    8: "Август",
    9: "Септември",
    10: "Октомври",
    11: "Ноември",
    12: "Декември"
  }
};
