import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_LOG = "getLog";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingLog";
export const SET_LOGS = "setLogs";

const state = {
  errors: {},
  logs: [],
  isLoadingLogs: false
};

const getters = {
  logs(state) {
    return state.logs;
  },
  isLoadingLogs() {
    return state.isLoadingLogs;
  }
};

const actions = {
  [GET_LOG](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      let url = "logs/last-by-type/" + payload.type + "/" + payload.id;

      if (payload.group) {
        url += "/" + payload.group;
      }

      ApiService.get(url)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_LOGS](state, logs) {
    state.isLoadingLogs = false;
    state.logs = logs;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingLogs = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
