import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_HALLS = "getHalls";
export const GET_ALL_HALLS = "getAllHalls";
export const GET_HALL = "getHall";
export const SAVE_HALL = "saveHall";
export const UPDATE_HALL = "updateHall";
export const DELETE_HALL = "deleteHall";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingHall";
export const SET_HALLS = "setHalls";

const state = {
  errors: {},
  halls: [],
  isLoadingHalls: false
};

const getters = {
  halls(state) {
    return state.halls;
  },
  isLoadingHalls() {
    return state.isLoadingHalls;
  }
};

const actions = {
  [GET_HALLS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("halls/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_HALLS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("halls")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_HALL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("halls", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_HALL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("halls", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_HALL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("halls/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_HALL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("halls/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_HALLS](state, halls) {
    state.isLoadingHalls = false;
    state.halls = halls;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingHalls = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
