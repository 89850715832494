import ApiService from "@/common/api.service";

//Actions
export const GET_WORKING_TIME = "getWorkingTime";
export const UPDATE_WORKING_TIME = "updateWorkingTime";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStat";
export const SET_WORKING_TIME = "setWorkingTime";

const state = {
  errors: {},
  workingTime: [],
  isLoadingWorkingTime: false
};

const getters = {
  workingTime(state) {
    return state.workingTime;
  },
  isLoadingWorkingTime() {
    return state.isLoadingWorkingTime;
  }
};

const actions = {
  [GET_WORKING_TIME](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("working-time")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_WORKING_TIME](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("working-time", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_WORKING_TIME](state, workingTime) {
    state.isLoadingWorkingTime = false;
    state.workingTime = workingTime;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingWorkingTime = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
