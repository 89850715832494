import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ROUTEMAPS_OPERATIONS_DATA = "getRoutemapsOperationsData";
export const GET_ALL_ROUTEMAPS_OPERATIONS = "getAllRoutemapsOperations";
export const GET_ROUTEMAPS_OPERATIONS = "getRoutemapsOperations";
export const SAVE_ROUTEMAPS_OPERATIONS = "saveRoutemapsOperations";
export const SAVE_ROUTEMAPS_HALL_OPERATIONS = "saveRoutemapsHallOperations";
export const SAVE_ROUTEMAPS_SIGN_OPERATIONS = "saveRoutemapsSignOperations";
export const UPDATE_ROUTEMAPS_OPERATIONS = "updateRoutemapsOperations";
export const DELETE_ROUTEMAPS_OPERATIONS = "deleteRoutemapsOperations";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingRoutemapsOperations";
export const SET_ROUTEMAPS_OPERATIONS = "setRoutemapsOperations";

const state = {
  errors: {},
  routemaps_operations: [],
  isLoadingRoutemapsOperations: false
};

const getters = {
  routemaps_operations(state) {
    return state.routemaps_operations;
  },
  isLoadingRoutemapsOperations() {
    return state.isLoadingRoutemapsOperations;
  }
};

const actions = {
  [GET_ROUTEMAPS_OPERATIONS_DATA](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-operations/data", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_ROUTEMAPS_OPERATIONS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps-operations")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAPS_OPERATIONS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps-operations", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROUTEMAPS_OPERATIONS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-operations", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROUTEMAPS_HALL_OPERATIONS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-operations-hall", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROUTEMAPS_SIGN_OPERATIONS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-operations/sign", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ROUTEMAPS_OPERATIONS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("route-maps-operations/" + payload.id, payload.form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ROUTEMAPS_OPERATIONS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("route-maps-operations/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ROUTEMAPS_OPERATIONS](state, routemaps_operations) {
    state.isLoadingRoutemapsOperations = false;
    state.routemaps_operations = routemaps_operations;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingRoutemapsOperations = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
