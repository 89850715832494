import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_DESTRUCTION_PACKAGING_MATERIALS =
  "getDestructionPackagingMaterials";
export const GET_ALL_DESTRUCTION_PACKAGING_MATERIALS =
  "getAllDestructionPackagingMaterials";
export const GET_DESTRUCTION_PACKAGING_MATERIAL =
  "getDestructionPackagingMaterial";
export const SAVE_DESTRUCTION_PACKAGING_MATERIAL =
  "saveDestructionPackagingMaterial";
export const UPDATE_DESTRUCTION_PACKAGING_MATERIAL =
  "updateDestructionPackagingMaterial";
export const SIGN_DESTRUCTION_PACKAGING_MATERIAL =
  "signDestructionPackagingMaterial";
export const DELETE_DESTRUCTION_PACKAGING_MATERIAL =
  "deleteDestructionPackagingMaterial";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingDestructionPackagingMaterial";
export const SET_DESTRUCTION_PACKAGING_MATERIALS =
  "setDestructionPackagingMaterials";

const state = {
  errors: {},
  destructionPackagingMaterials: {},
  isLoadingDestructionPackagingMaterials: false
};

const getters = {
  destructionPackagingMaterials(state) {
    return state.destructionPackagingMaterials;
  },
  isLoadingDestructionPackagingMaterials() {
    return state.isLoadingDestructionPackagingMaterials;
  }
};

const actions = {
  [GET_DESTRUCTION_PACKAGING_MATERIALS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("destruction-packaging-materials/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_DESTRUCTION_PACKAGING_MATERIALS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("destruction-packaging-materials")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_DESTRUCTION_PACKAGING_MATERIAL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("destruction-packaging-materials", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SIGN_DESTRUCTION_PACKAGING_MATERIAL](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get("destruction-packaging-materials/sign/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [SAVE_DESTRUCTION_PACKAGING_MATERIAL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("destruction-packaging-materials", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_DESTRUCTION_PACKAGING_MATERIAL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(
        "destruction-packaging-materials/" + payload.payload.id,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_DESTRUCTION_PACKAGING_MATERIALS](state, destructionPackagingMaterials) {
    state.isLoadingDestructionPackagingMaterials = false;
    state.destructionPackagingMaterials = destructionPackagingMaterials;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingDestructionPackagingMaterials = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
