import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_PRINTING_HOUSES = "getPrintingHouses";
export const GET_ALL_PRINTING_HOUSES = "getAllPrintingHouses";
export const GET_PRINTING_HOUSE = "getPrintingHouse";
export const SAVE_PRINTING_HOUSE = "savePrintingHouse";
export const UPDATE_PRINTING_HOUSE = "updatePrintingHouse";
export const DELETE_PRINTING_HOUSE = "deletePrintingHouse";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingPrintingHouse";
export const SET_PRINTING_HOUSES = "setPrintingHouses";

const state = {
    errors: {},
    printingHouse: [],
    isLoadingPrintingHouses: false
};

const getters = {
    printingHouse(state) {
        return state.printingHouse;
    },
    isLoadingPrintingHouses() {
        return state.isLoadingPrintingHouses;
    }
};

const actions = {
    [GET_PRINTING_HOUSES](context, payload = "") {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.post("printing-houses/data", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_ALL_PRINTING_HOUSES](context) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("printing-houses")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_PRINTING_HOUSE](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("printing-houses", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [SAVE_PRINTING_HOUSE](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.post("printing-houses", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [UPDATE_PRINTING_HOUSE](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.put("printing-houses/" + payload.payload.id, payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [DELETE_PRINTING_HOUSE](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.delete("printing-houses/" + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_PRINTING_HOUSES](state, printingHouse) {
        state.isLoadingPrintingHouses = false;
        state.printingHouse = printingHouse;
        state.errors = {};
    },
    [SET_LOADING](state, value) {
        state.isLoadingPrintingHouses = value;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};