import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa"
  },
  theme: {
    themes: {
      light: {
        besta: "#25bdad"
      },
      dark: {
        besta: "#25bdad"
      }
    },
    options: {
      customProperties: true
    }
  }
});
