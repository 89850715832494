import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_DATA = "getData";
export const GET_MATERIALTYPE = "getMaterialType";
export const SAVE_MATERIALTYPE = "saveMaterialType";
export const UPDATE_MATERIALTYPE = "updateMaterialType";
export const DELETE_MATERIALTYPE = "deleteMaterialType";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMaterialType";
export const SET_MATERIALTYPES = "setMaterialTypes";

const state = {
    errors: {},
    materialTypes: [],
    isLoadingMaterialTypes: false
};

const getters = {
    materialTypes(state) {
        return state.materialTypes;
    },
    isLoadingMaterialTypes() {
        return state.isLoadingMaterialTypes;
    }
};

const actions = {
    [GET_DATA](context, payload) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.post("selects", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_MATERIALTYPE](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("materialTypes", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [SAVE_MATERIALTYPE](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.post("materialTypes", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [UPDATE_MATERIALTYPE](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.put("materialTypes/" + payload.payload.id, payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [DELETE_MATERIALTYPE](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.delete("materialTypes/" + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_MATERIALTYPES](state, materialTypes) {
        state.isLoadingMaterialTypes = false;
        state.materialTypes = materialTypes;
        state.errors = {};
    },
    [SET_LOADING](state, value) {
        state.isLoadingMaterialTypes = value;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};