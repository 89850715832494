import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_MATERIALREQUESTS = "getMaterialRequests";
export const GET_ALL_MATERIALREQUESTS = "getAllMaterialRequests";
export const GET_MATERIALREQUEST = "getMaterialRequest";
export const GET_MATERIALREQUEST_DETAILS_FOR_PRODUCT =
  "getMaterialRequestDetailsForProduct";
export const GET_MATERIALREQUEST_NO_LOAD = "getMaterialRequestNoLoad";
export const SAVE_MATERIALREQUEST = "saveMaterialRequest";
export const UPDATE_MATERIALREQUEST = "updateMaterialRequest";
export const DELETE_MATERIALREQUEST = "deleteMaterialRequest";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMaterialRequest";
export const SET_MATERIALREQUESTS = "setMaterialRequests";

const state = {
  errors: {},
  materialrequests: [],
  isLoadingMaterialRequest: false
};

const getters = {
  materialrequests(state) {
    return state.materialrequests;
  },
  isLoadingMaterialRequest() {
    return state.isLoadingMaterialRequest;
  }
};

const actions = {
  [GET_MATERIALREQUESTS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("material-request/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_MATERIALREQUESTS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("material-request", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MATERIALREQUEST](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("material-request", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MATERIALREQUEST_DETAILS_FOR_PRODUCT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("material-request/get-details-for-product/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MATERIALREQUEST_NO_LOAD](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get("material-request", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [SAVE_MATERIALREQUEST](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("material-request", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_MATERIALREQUEST](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("material-request/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MATERIALREQUEST](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("material-request/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {
          reject({
            response: {
              access:
                "Приемно предавателния протокол не може да бъде изтрит, тъй като вече е в производство!"
            }
          });
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MATERIALREQUESTS](state, materialrequests) {
    state.isLoadingMaterialRequest = false;
    state.materialrequests = materialrequests;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingMaterialRequest = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
