import ApiService from "@/common/api.service";

//Actions
export const CREATE_CERTIFICATE_REANALYSIS_REQUEST =
  "createCertificateReanalysisRequest";
export const APPROVE_CERTIFICATE_REANALYSIS_REQUEST =
  "approveCertificateReanalysisRequest";
export const DECLINE_CERTIFICATE_REANALYSIS_REQUEST =
  "declineCertificateReanalysisRequest";
export const PROCESS_CERTIFICATE_REANALYSIS_REQUEST =
  "processCertificateReanalysisRequest";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  [CREATE_CERTIFICATE_REANALYSIS_REQUEST](context, certificateId) {
    return new Promise((resolve, reject) => {
      ApiService.post(`certificate-reanalysis-requests/store/${certificateId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [APPROVE_CERTIFICATE_REANALYSIS_REQUEST](context, certificateId) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `certificate-reanalysis-requests/approve/${certificateId}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [DECLINE_CERTIFICATE_REANALYSIS_REQUEST](context, certificateId) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `certificate-reanalysis-requests/decline/${certificateId}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  [PROCESS_CERTIFICATE_REANALYSIS_REQUEST](context, certificateId) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `certificate-reanalysis-requests/process/${certificateId}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  //
};

export default {
  state,
  actions,
  mutations,
  getters,
};
