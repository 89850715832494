import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../store";
import router from "../router";
import JwtService from "@/common/jwt.service";
import { API_URL } from "@/common/config";
import { CHECK_TOKEN } from "../store/auth.module";
import KTUtil from "@/assets/js/util.js";

/**
 * Check if access token has expired before request and refresh it
 */
const createAxiosResponseInterceptor = () => {
  const interceptor = axios.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
      if (
        error.response.status !== 401 ||
        error.response.config.url === "oauth/token"
      ) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      axios.interceptors.response.eject(interceptor);
      store
        .dispatch(CHECK_TOKEN)
        .then(data => {
          error.response.config.headers["Authorization"] =
            "Bearer " + data.access_token;
          return axios(error.response.config);
        })
        .catch(error => {
          router.push({ name: "login" });
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
};

/**
 * Set the default HTTP request headers
 */
const setHeader = () => {
  axios.interceptors.request.use(config => {
    let token = JwtService.getToken();
    //
    //
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });
};

/**
 * Setup KT Loader for axios request and response
 */
const setupKtLoader = () => {
  axios.interceptors.response.use(
    response => {
      KTUtil.closeKtLoading();

      return response;
    },
    error => {
      KTUtil.closeKtLoading();

      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(config => {
    if (
      !config.url.includes("/data") &&
      !config.url.includes("selects") &&
      config.method !== "get"
    ) {
      KTUtil.openKtLoading();
    }

    return config;
  });
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;

    setupKtLoader();
    setHeader();
    createAxiosResponseInterceptor();
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      //
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", header = "") {
    if (slug.length == 0) {
      return Vue.axios.get(`${resource}`, header);
    } else {
      return Vue.axios.get(`${resource}/${slug}`, header);
    }
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      //
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
