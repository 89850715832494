import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_USERS = "getUsers";
export const GET_ALL_USERS = "getAllUsers";
export const GET_USER = "getUser";
export const SAVE_USER = "saveUser";
export const UPDATE_USER = "updateUser";
export const DELETE_USER = "deleteUser";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingUser";
export const SET_USERS = "setUsers";

const state = {
  errors: {},
  users: [],
  isLoadingUsers: false
};

const getters = {
  users(state) {
    return state.users;
  },
  isLoadingUsers() {
    return state.isLoadingUsers;
  }
};

const actions = {
  [GET_USERS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("users/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_USERS](context, search = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("users" + search)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_USER](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("users", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_USER](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("users", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("users/" + payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_USER](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("users/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USERS](state, users) {
    state.isLoadingUsers = false;
    state.users = users;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingUsers = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
