import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_MATERIALPROPERTIES = "getMaterialProperties";
export const GET_MATERIALPROPERTIE = "getMaterialPropertie";
export const SAVE_MATERIALPROPERTIE = "saveMaterialPropertie";
export const UPDATE_MATERIALPROPERTIE = "updateMaterialPropertie";
export const DELETE_MATERIALPROPERTIE = "deleteMaterialPropertie";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMaterialPropertie";
export const SET_MATERIALPROPERTIES = "setMaterialProperties";

const state = {
  errors: {},
  materialProperties: [],
  isLoadingMaterialProperties: false
};

const getters = {
  materialProperties(state) {
    return state.materialProperties;
  },
  isLoadingMaterialProperties() {
    return state.isLoadingMaterialProperties;
  }
};

const actions = {
  [GET_MATERIALPROPERTIES](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("materials-properties")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MATERIALPROPERTIE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("materials-properties", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_MATERIALPROPERTIE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("materials-properties", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_MATERIALPROPERTIE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(
        "materials-properties/" + payload.payload.id,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MATERIALPROPERTIE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("materials-properties/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {
          reject({
            response: {
              access:
                "Изискването не може да бъде изтрито, защото има свързани материали!"
            }
          });
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MATERIALPROPERTIES](state, materialProperties) {
    state.isLoadingMaterialProperties = false;
    state.materialProperties = materialProperties;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingMaterialProperties = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
