import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_PRODUCTFORMULAS = "getProductFormulas";
export const GET_PRODUCTFORMULA = "getProductFormula";
export const SAVE_PRODUCTFORMULA = "saveProductFormula";
export const UPDATE_PRODUCTFORMULA = "updateProductFormula";
export const DELETE_PRODUCTFORMULA = "deleteProductFormula";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingProductFormula";
export const SET_PRODUCTFORMULAS = "setProductFormulas";

const state = {
  errors: {},
  productformulas: [],
  isLoadingProductFormulas: false
};

const getters = {
  productformulas(state) {
    return state.productformulas;
  },
  isLoadingProductFormulas() {
    return state.isLoadingProductFormulas;
  }
};

const actions = {
  [GET_PRODUCTFORMULAS](context, id = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("product-formulas?productId=" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PRODUCTFORMULA](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("product-formulas", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_PRODUCTFORMULA](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("product-formulas", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PRODUCTFORMULA](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("product-formulas/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_PRODUCTFORMULA](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("product-formulas/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_PRODUCTFORMULAS](state, productformulas) {
    state.isLoadingProductFormulas = false;
    state.productformulas = productformulas;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingProductFormulas = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
