import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ALL_FINAL_PRODUCT_SALE_REGISTER =
  "getAllFinalProductSaleRegister";
export const DOWNLOAD_FINAL_PRODUCT_SALE_REGISTER =
  "downloadFinalProductSaleRegister";
//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingFinalProductSaleRegister";
export const SET_FINAL_PRODUCT_SALE_REGISTER = "setFinalProductSaleRegister";

const state = {
  errors: {},
  finalProductSaleRegisters: [],
  isLoadingFinalProductSaleRegister: false
};

const getters = {
  finalProductSaleRegisters(state) {
    return state.finalProductSaleRegisters;
  },
  isLoadingFinalProductSaleRegister() {
    return state.isLoadingFinalProductSaleRegister;
  }
};

const actions = {
  [GET_ALL_FINAL_PRODUCT_SALE_REGISTER](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("final-product-sale/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DOWNLOAD_FINAL_PRODUCT_SALE_REGISTER](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("files/get/pdf")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FINAL_PRODUCT_SALE_REGISTER](state, finalProductSaleRegisters) {
    state.isLoadingFinalProductSaleRegister = false;
    state.finalProductSaleRegisters = finalProductSaleRegisters;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingFinalProductSaleRegister = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
