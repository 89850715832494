import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_WAREHOUSES = "getWarehouses";
export const GET_ALL_WAREHOUSES = "getAllWarehouses";
export const GET_WAREHOUSE = "getWarehouse";
export const SAVE_WAREHOUSE = "saveWarehouse";
export const UPDATE_WAREHOUSE = "updateWarehouse";
export const DELETE_WAREHOUSE = "deleteWarehouse";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingWarehouse";
export const SET_WAREHOUSES = "setWarehouses";

const state = {
    errors: {},
    warehouses: [],
    isLoadingWarehouses: false
};

const getters = {
    warehouses(state) {
        return state.warehouses;
    },
    isLoadingWarehouses() {
        return state.isLoadingWarehouses;
    }
};

const actions = {
    [GET_WAREHOUSES](context, payload = "") {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.post("warehouses/data", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_ALL_WAREHOUSES](context) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("warehouses")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_WAREHOUSE](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("warehouses", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [SAVE_WAREHOUSE](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.post("warehouses", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [UPDATE_WAREHOUSE](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.put("warehouses/" + payload.payload.id, payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [DELETE_WAREHOUSE](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.delete("warehouses/" + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_WAREHOUSES](state, warehouses) {
        state.isLoadingWarehouses = false;
        state.warehouses = warehouses;
        state.errors = {};
    },
    [SET_LOADING](state, value) {
        state.isLoadingWarehouses = value;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};