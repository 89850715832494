import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_EMAILS = "getEmails";
export const GET_ALL_EMAILS = "getAllEmails";
export const GET_EMAIL = "getEmail";
export const SAVE_EMAIL = "saveEmail";
export const SEND_EMAIL = "sendEmail";
export const UPDATE_EMAIL = "updateEmail";
export const DELETE_EMAIL = "deleteEmail";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingEmail";
export const SET_EMAILS = "setEmails";

const state = {
  errors: {},
  emails: [],
  isLoadingEmails: false
};

const getters = {
  emails(state) {
    return state.emails;
  },
  isLoadingEmails() {
    return state.isLoadingEmails;
  }
};

const actions = {
  [GET_EMAILS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("emails/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_EMAILS](context, search = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("emails" + search)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_EMAIL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("emails", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_EMAIL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("emails", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SEND_EMAIL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("emails/send", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_EMAIL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("emails/" + payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_EMAIL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("emails/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_EMAILS](state, emails) {
    state.isLoadingEmails = false;
    state.emails = emails;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingEmails = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
