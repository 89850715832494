import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_CERTIFICATE_TYPES = "getCertificateTypes";
export const GET_ALL_CERTIFICATE_TYPES = "getAllCertificateTypes";
export const GET_PRODUCT_CERTIFICATE_TYPES = "getProductCertificateTypes";
export const GET_CERTIFICATE_TYPE = "getCertificateType";
export const SAVE_CERTIFICATE_TYPE = "saveCertificateType";
export const UPDATE_CERTIFICATE_TYPE = "updateCertificateType";
export const DELETE_CERTIFICATE_TYPE = "deleteCertificateType";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingCertificateType";
export const SET_CERTIFICATE_TYPES = "setCertificateTypes";

const state = {
  errors: {},
  certificateTypes: {},
  isLoadingCertificateTypes: false
};

const getters = {
  certificateTypes(state) {
    return state.certificateTypes;
  },
  isLoadingCertificateTypes() {
    return state.isLoadingCertificateTypes;
  }
};

const actions = {
  [GET_CERTIFICATE_TYPES](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("certificate-types/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_CERTIFICATE_TYPES](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificate-types")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PRODUCT_CERTIFICATE_TYPES](context, id = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificate-types/product/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CERTIFICATE_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificate-types", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_CERTIFICATE_TYPE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("certificate-types", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_CERTIFICATE_TYPE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("certificate-types/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_CERTIFICATE_TYPE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("certificate-types/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CERTIFICATE_TYPES](state, certificateTypes) {
    state.isLoadingCertificateTypes = false;
    state.certificateTypes = certificateTypes;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingCertificateTypes = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
