import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_FILES = "getFiles";
export const GET_FILE = "getFile";
export const STREAM_FILE = "streamFile";
export const GET_FILES_BY_TYPE = "getFilesByType";
export const SAVE_FILE = "saveFile";
export const UPDATE_FILE = "updateFile";
export const DELETE_FILE = "deleteFile";
export const MERGE_ROUTEMAP_FILE = "mergeRoutemapFile";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingFile";
export const SET_FILES = "setFiles";

const state = {
  errors: {},
  files: [],
  isLoadingFiles: false
};

const getters = {
  files(state) {
    return state.files;
  },
  isLoadingFiles() {
    return state.isLoadingFiles;
  }
};

const actions = {
  [GET_FILES](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("files/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_FILE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("files/get", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [MERGE_ROUTEMAP_FILE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("files/merge/route-map/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [STREAM_FILE](context, code) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("file", code)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_FILES_BY_TYPE](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("files/" + payload.file_type + "/" + payload.resource_id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_FILE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("files", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_FILE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("files/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_FILE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("files/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FILES](state, files) {
    state.isLoadingFiles = false;
    state.files = files;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingFiles = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
