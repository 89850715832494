import ApiService from "@/common/api.service";

//Actions
export const GET_WORK_BREAKS = "getWorkBreaks";
export const GET_WORK_BREAK = "getWorkBreak";
export const SAVE_WORK_BREAK = "saveWorkBreak";
export const UPDATE_WORK_BREAK = "updateWorkBreak";
export const DELETE_WORK_BREAK = "deleteWorkBreak";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStat";
export const SET_WORK_BREAKS = "setWorkBreaks";

const state = {
  errors: {},
  workBreaks: [],
  isLoadingWorkBreaks: false
};

const getters = {
  workBreaks(state) {
    return state.workBreaks;
  },
  isLoadingWorkBreaks() {
    return state.isLoadingWorkBreaks;
  }
};

const actions = {
  [GET_WORK_BREAKS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("work-breaks/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_WORK_BREAK](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("work-breaks", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_WORK_BREAK](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("work-breaks", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_WORK_BREAK](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("work-breaks/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_WORK_BREAK](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("work-breaks/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_WORK_BREAKS](state, workBreaks) {
    state.isLoadingWorkBreaks = false;
    state.workBreaks = workBreaks;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingWorkBreaks = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
