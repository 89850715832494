export const API_URL = "https://edoc.bestapharmaceuticals.bg/api/";
export const APP_URL = "https://edoc.bestapharmaceuticals.bg/api/";
export var OauthClient = (grant, params) => {
  var clientData = {
    grant_type: grant,
    client_id: 1,
    client_secret: "3YlRuSbKtoVaCdKEesMapx4ZMeggyOIybMBnv4O5",
    scope: "full-access"
  };

  if (params && typeof params === "object") {
    clientData = Object.assign(clientData, params);
  }

  return clientData;
};

export default API_URL;
