import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_CERTIFICATES = "getCertificates";
export const GET_CERTIFICATES_BY_MATERIAL_ID = "getCertificatesByMaterialId";
export const GET_ALL_CERTIFICATES = "getAllCertificates";
export const GET_CERTIFICATE_BATCHES = "getCertificateBatches";
export const GET_PRODUCT_CERTIFICATES = "getProductCertificates";
export const GET_CERTIFICATE = "getCertificate";
export const GET_CERTIFICATES_YEARS = "getCertificatesYears";
export const GENERATE_CERTIFICATE = "generateCertificate";
export const BOM_REGISTER = "bomRegister";
export const SAVE_CERTIFICATE = "saveCertificate";
export const UPDATE_CERTIFICATE = "updateCertificate";
export const SIGN_CERTIFICATE = "signCertificate";
export const DELETE_CERTIFICATE = "deleteCertificate";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingCertificate";
export const SET_CERTIFICATES = "setCertificates";

const state = {
  errors: {},
  certificates: {},
  isLoadingCertificates: false,
};

const getters = {
  certificates(state) {
    return state.certificates;
  },
  isLoadingCertificates() {
    return state.isLoadingCertificates;
  },
};

const actions = {
  [GET_CERTIFICATES](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("certificates/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CERTIFICATES_YEARS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("certificates/years")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CERTIFICATES_BY_MATERIAL_ID](context, id = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificates/by-material/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [BOM_REGISTER](context, year) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("certificates/bom-register", { year })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_CERTIFICATES](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificates")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PRODUCT_CERTIFICATES](context, id = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificates/product/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CERTIFICATE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificates", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CERTIFICATE_BATCHES](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("certificates/batches?materialIds=" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_CERTIFICATE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("certificates/generate/" + payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SIGN_CERTIFICATE](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get("certificates/sign/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [SAVE_CERTIFICATE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("certificates", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_CERTIFICATE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("certificates/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_CERTIFICATE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("certificates/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {
          reject({
            response: {
              access:
                "Сертификата не може да бъде изтрит, тъй като вече е в производство или има унищожени бройки от него!",
            },
          });
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CERTIFICATES](state, certificates) {
    state.isLoadingCertificates = false;
    state.certificates = certificates;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingCertificates = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
