import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_MATERIALS = "getMaterials";
export const GET_ALL_MATERIALS = "getAllMaterials";
export const GET_PRODUCT_MATERIALS = "getProductMaterials";
export const GET_MATERIAL = "getMaterial";
export const SAVE_MATERIAL = "saveMaterial";
export const UPDATE_MATERIAL = "updateMaterial";
export const DELETE_MATERIAL = "deleteMaterial";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingMaterial";
export const SET_MATERIALS = "setMaterials";

const state = {
  errors: {},
  materials: {},
  isLoadingMaterials: false
};

const getters = {
  materials(state) {
    return state.materials;
  },
  isLoadingMaterials() {
    return state.isLoadingMaterials;
  }
};

const actions = {
  [GET_MATERIALS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("materials/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_MATERIALS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("materials")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PRODUCT_MATERIALS](context, id = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("materials/product/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_MATERIAL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("materials", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_MATERIAL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("materials", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_MATERIAL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("materials/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MATERIAL](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("materials/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {
          reject({
            response: {
              access:
                "Материалът не може да бъде изтрит, защото има свързани сертификати!"
            }
          });
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MATERIALS](state, materials) {
    state.isLoadingMaterials = false;
    state.materials = materials;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingMaterials = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
