import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_VENDORS = "getVendors";
export const GET_ALL_VENDORS = "getAllVendors";
export const GET_VENDOR = "getVendor";
export const SAVE_VENDOR = "saveVendor";
export const UPDATE_VENDOR = "updateVendor";
export const DELETE_VENDOR = "deleteVendor";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingVendor";
export const SET_VENDORS = "setVendors";

const state = {
    errors: {},
    vendors: [],
    isLoadingVendors: false
};

const getters = {
    vendors(state) {
        return state.vendors;
    },
    isLoadingVendors() {
        return state.isLoadingVendors;
    }
};

const actions = {
    [GET_VENDORS](context, payload = "") {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.post("vendors/data", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_ALL_VENDORS](context) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("vendors")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_VENDOR](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("vendors", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [SAVE_VENDOR](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.post("vendors", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [UPDATE_VENDOR](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.put("vendors/" + payload.payload.id, payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [DELETE_VENDOR](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.delete("vendors/" + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_VENDORS](state, vendors) {
        state.isLoadingVendors = false;
        state.vendors = vendors;
        state.errors = {};
    },
    [SET_LOADING](state, value) {
        state.isLoadingVendors = value;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};