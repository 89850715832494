import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", value => {
  return moment(String(value)).format("DD/MM/YYYY");
});

Vue.filter("formatDateTime", value => {
  return moment(String(value)).format("HH:mm DD/MM/YYYY");
});
