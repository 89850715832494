import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_OPERATORS_DATA = "getOperatorsData";
export const GET_OPERATORS_DATA_ROUTEMAP = "getOperatorsDataRoutemap";
export const GET_ALL_OPERATORS_DATA = "getAllOperatorsData";
export const SAVE_OPERATORS_DATA = "saveOperatorsData";
export const UPDATE_OPERATORS_DATA = "updateOperatorsData";
export const DELETE_OPERATORS_DATA = "deleteOperatorsData";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingOperatorsData";
export const SET_OPERATORS_DATA = "setOperatorsData";

const state = {
  errors: {},
  operators_data: [],
  isLoadingOperatorsData: false
};

const getters = {
  operators_data(state) {
    return state.operators_data;
  },
  isLoadingOperatorsData() {
    return state.isLoadingOperatorsData;
  }
};

const actions = {
  [GET_OPERATORS_DATA](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("operators-data/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_OPERATORS_DATA](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("operators-data")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_OPERATORS_DATA_ROUTEMAP](context, op_key) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("operators-data/route-maps", "", {
        headers: {
          "Operator-Key": op_key
        }
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_OPERATORS_DATA](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("operators-data", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_OPERATORS_DATA](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("operators-data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_OPERATORS_DATA](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("operators-data/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_OPERATORS_DATA](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("operators-data/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_OPERATORS_DATA](state, operators_data) {
    state.isLoadingOperatorsData = false;
    state.operators_data = operators_data;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingOperatorsData = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
