import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ALL_SCRAP_REGISTER = "getAllScrapRegister";
export const DOWNLOAD_SCRAP_REGISTER = "downloadScrapRegister";
//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingScrapRegister";
export const SET_SCRAP_REGISTER = "setScrapRegister";

const state = {
  errors: {},
  scrapRegisters: [],
  isLoadingScrapRegister: false
};

const getters = {
  scrapRegisters(state) {
    return state.scrapRegisters;
  },
  isLoadingScrapRegister() {
    return state.isLoadingScrapRegister;
  }
};

const actions = {
  [GET_ALL_SCRAP_REGISTER](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get(
        `scrap?search=${payload.search}&client=${payload.client}&start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DOWNLOAD_SCRAP_REGISTER](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("files/get/pdf")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SCRAP_REGISTER](state, scrapRegisters) {
    state.isLoadingScrapRegister = false;
    state.scrapRegisters = scrapRegisters;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingScrapRegister = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
