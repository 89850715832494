import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_PROFILE = "getProfile";
export const GET_TIME_TO_CHANGE_PASSWORD = "getTimeForChangePassword";
export const UPDATE_PROFILE = "updateProfile";

//Mutations
export const SET_ERROR = "setError";
export const SET_TIME_TO_CHANGE_PASSWORD = "setTimeToChangePassword";
export const SET_LOADING = "setLoadingProfile";

const state = {
  errors: {},
  users: [],
  timeToChangePassword: false,
  isLoadingProfile: false
};

const getters = {
  isLoadingProfile() {
    return state.isLoadingProfile;
  },
  timeToChangePassword() {
    return state.timeToChangePassword;
  }
};

const actions = {
  [GET_PROFILE](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("profile")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_TIME_TO_CHANGE_PASSWORD](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("profile/time-for-change-password")
        .then(({ data }) => {
          context.commit(
            SET_TIME_TO_CHANGE_PASSWORD,
            data.data.time_to_change_password
          );
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PROFILE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("profile", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TIME_TO_CHANGE_PASSWORD](state, value) {
    state.timeToChangePassword = value;
  },
  [SET_LOADING](state, value) {
    state.isLoadingProfile = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
