import ApiService from "@/common/api.service";

// action types
//Actions
export const START_ROUTEMAP = "startRoutemap";
export const PAUSE_ROUTEMAP = "pauseRoutemap";
export const FINISH_ROUTEMAP = "finishRoutemap";
export const GET_ROUTEMAPS = "getRoutemaps";
export const GET_ROUTEMAPS_BY_PERIOD = "getRoutemapsBYPeriod";
export const GET_ROUTEMAPS_BY_CERTIFICATE = "getRoutemapsByCertificate";
export const GET_ALL_ROUTEMAPS = "getAllRoutemaps";
export const GET_ACTIVE_ROUTEMAPS = "getActiveRoutemaps";
export const GET_ROUTEMAPS_FOR_LOAD = "getRoutemapsForLoad";
export const SIGN_LOAD = "signLoad";
export const FIX_CONTROL = "fixControl";
export const GET_AUTHORIZATION_HOLDER = "getAuthorizationHolder";
export const GET_ROUTEMAP = "getRoutemap";
export const GET_ROUTEMAP_INFO = "getRoutemapInfo";
export const GET_ROUTEMAP_INFO_HALL = "getRoutemapInfoHall";
export const GET_ROUTEMAP_INFO_NO_LOAD = "getRoutemapInfoNoLoad";
export const GET_ROUTEMAP_TIME_INFO = "getRoutemapTimeInfo";
export const GET_ROUTEMAP_ACTIVE_INFO = "getRoutemapActiveInfo";
export const SAVE_ROUTEMAP = "saveRoutemap";
export const SAVE_HALLS = "saveHalls";
export const SIGN_ROUTEMAP = "signRoutemap";
export const BLOCK_ROUTEMAP = "blockRoutemap";
export const EDIT_AUTHORIZATION_HOLDER_ROUTEMAP =
  "editAuthorizationHolderRoutemap";
export const REGENERATE_ROUTEMAP_FILES = "regenerateRouteMapFiles";
export const RAW_PRODUCT_SCRAP = "rawProductScrapRoutemap";
export const UPDATE_ROUTEMAP = "updateRoutemap";
export const SCRAP_MATERIAL = "scrapMaterial";
export const DELETE_RAW_PRODUCT_SCRAP = "deleteRawProductScrap";
export const DELETE_MATERIAL_SCRAP = "deleteMaterialScrap";
export const DELETE_ROUTEMAP = "deleteRoutemap";
export const ROUTEMAP_UPDATE_NOTE = "routemapUpdateNote";
export const HALL_CONFIRM_CLEANING = "hallConfirmCleaning";
export const ROUTE_MAP_ADD_COMMENT = "addComment";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingRoutemap";
export const SET_ROUTEMAPS = "setRoutemaps";

const state = {
  errors: {},
  routemaps: [],
  isLoadingRoutemaps: false
};

const getters = {
  routemaps(state) {
    return state.routemaps;
  },
  isLoadingRoutemaps() {
    return state.isLoadingRoutemaps;
  }
};

const actions = {
  [GET_ROUTEMAPS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAPS_BY_PERIOD](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(
        "route-maps/by-period/" + payload.clientId,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAPS_BY_CERTIFICATE](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/by-certificate/" + payload.certificateId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SCRAP_MATERIAL](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/scrap-material/" + payload.id, payload.form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_RAW_PRODUCT_SCRAP](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/delete-raw-product-scrap/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_MATERIAL_SCRAP](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/delete-material-scrap/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAPS_FOR_LOAD](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("labourers/route-maps", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SIGN_LOAD](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("labourers/sign", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FIX_CONTROL](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("labourers/fix-control", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [HALL_CONFIRM_CLEANING](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.put(
        "hall-cleanings/by-route-map/" + payload.routemapId,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [START_ROUTEMAP](context, payload = "") {
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/start/" + payload.id, payload.form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_AUTHORIZATION_HOLDER](context, payload = "") {
    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/authorization-holder/" + payload.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [PAUSE_ROUTEMAP](context, payload = "") {
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/pause/" + payload.id, payload.form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [FINISH_ROUTEMAP](context, payload = "") {
    // context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/finish/" + payload.id, payload.form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_ROUTEMAPS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ACTIVE_ROUTEMAPS](context, hallId) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/active/" + hallId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAP](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAP_INFO](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/show-info", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAP_INFO_HALL](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/show-info-hall")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SIGN_ROUTEMAP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/sign/" + payload.id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [BLOCK_ROUTEMAP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/block/" + payload.id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [EDIT_AUTHORIZATION_HOLDER_ROUTEMAP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(
        "route-maps/edit-authorization-holder/" + payload.id,
        payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [REGENERATE_ROUTEMAP_FILES](context, routeMapId) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/regenrate-files/" + routeMapId, [])
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_HALLS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("route-maps/halls/" + payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [GET_ROUTEMAP_INFO_NO_LOAD](context, id) {
    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/show-info", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {});
    });
  },
  [GET_ROUTEMAP_TIME_INFO](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/time-info", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAP_ACTIVE_INFO](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/show-active")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAP_ACTIVE_INFO](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps/show-active")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROUTEMAP](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [RAW_PRODUCT_SCRAP](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/product-scrap/" + payload.id, payload.form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ROUTEMAP](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("route-maps/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ROUTEMAP_UPDATE_NOTE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post(
        "route-maps/update-note/" + payload.payload.id,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [ROUTE_MAP_ADD_COMMENT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/comments", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ROUTEMAP](context, params) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps/destroy/" + params.id, { note: params.note })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ROUTEMAPS](state, routemaps) {
    state.isLoadingRoutemaps = false;
    state.routemaps = routemaps;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingRoutemaps = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
