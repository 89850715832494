import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_OPERATORS = "getOperators";
export const OPERATOR_LOGIN = "operatorLogin";
export const OPERATOR_LOGOUT = "operatorLogout";
export const OPERATOR_FORCE_LOGOUT = "operatorForceLogout";
export const GET_ALL_OPERATORS = "getAllOperators";
export const GET_OPERATOR = "getOperator";
export const SAVE_OPERATOR = "saveOperator";
export const UPDATE_OPERATOR = "updateOperator";
export const DELETE_OPERATOR = "deleteOperator";
export const GET_OPERATOR_HOLIDAYS = "getOperatorHolidays";
export const SAVE_OPERATOR_HOLIDAY = "saveOperatorHoliday";
export const UPDATE_OPERATOR_HOLIDAY = "updateOperatorHoliday";
export const DELETE_OPERATOR_HOLIDAY = "deleteOperatorHoliday";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingOperator";
export const SET_OPERATORS = "setOperators";

const state = {
  errors: {},
  operators: [],
  isLoadingOperators: false
};

const getters = {
  operators(state) {
    return state.operators;
  },
  isLoadingOperators() {
    return state.isLoadingOperators;
  }
};

const actions = {
  [GET_OPERATORS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("operators/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [OPERATOR_LOGIN](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("operator-login", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [OPERATOR_LOGOUT](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("operator-logout", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [OPERATOR_FORCE_LOGOUT](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("operator-force-logout", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_OPERATORS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("operators")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_OPERATOR](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("operators", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_OPERATOR](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("operators", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_OPERATOR](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("operators/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_OPERATOR](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("operators/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_OPERATOR_HOLIDAYS](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post(`operators-holidays/data/${payload.id}`, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_OPERATOR_HOLIDAY](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("operators-holidays", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_OPERATOR_HOLIDAY](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(`operators-holidays/${payload.id}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_OPERATOR_HOLIDAY](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("operators-holidays/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_OPERATORS](state, operators) {
    state.isLoadingOperators = false;
    state.operators = operators;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingOperators = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
