import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_INVOICES = "getInvoices";
export const GET_INVOICE = "getInvoice";
export const SAVE_INVOICE = "saveInvoice";
export const UPDATE_INVOICE = "updateInvoice";
export const GET_FREE_ROUTEMAPS = "getFreeRoutemaps";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingInvoices";

const state = {
  errors: {},
  isLoadingInvoices: false
};

const getters = {
  isLoadingInvoices() {
    return state.isLoadingInvoices;
  }
};

const actions = {
  [GET_INVOICES](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("invoices/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_INVOICE](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("invoices", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [SAVE_INVOICE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("invoices", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [UPDATE_INVOICE](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("invoices/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },

  [GET_FREE_ROUTEMAPS](context, payload) {
    context.commit(SET_LOADING, true);

    let path = "invoices/free-route-maps/" + payload.clientId;

    if (payload.invoiceId) {
      path += `/${payload.invoiceId}`;
    }

    return new Promise((resolve, reject) => {
      ApiService.get(path)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_LOADING](state, value) {
    state.isLoadingInvoices = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
