import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ACCEPTANCEACTS = "getAcceptanceActs";
export const GET_ALL_ACCEPTANCEACTS = "getAllAcceptanceActs";
export const GET_ACCEPTANCEACT = "getAcceptanceAct";
export const GET_ACCEPTANCEACT_DETAIL_FOR_PRODUCT =
  "getAcceptanceActDetailForProduct";
export const SAVE_ACCEPTANCEACT = "saveAcceptanceAct";
export const UPDATE_ACCEPTANCEACT = "updateAcceptanceAct";
export const DELETE_ACCEPTANCEACT = "deleteAcceptanceAct";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingAcceptanceAct";
export const SET_ACCEPTANCEACTS = "setAcceptanceActs";

const state = {
  errors: {},
  acceptanceacts: [],
  isLoadingAcceptanceActs: false
};

const getters = {
  acceptanceacts(state) {
    return state.acceptanceacts;
  },
  isLoadingAcceptanceActs() {
    return state.isLoadingAcceptanceActs;
  }
};

const actions = {
  [GET_ACCEPTANCEACTS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("acceptance-acts/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_ACCEPTANCEACTS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("acceptance-acts", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ACCEPTANCEACT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("acceptance-acts", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ACCEPTANCEACT_DETAIL_FOR_PRODUCT](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      const routeMapId = payload.routeMapId ?? "";

      ApiService.get(
        `acceptance-acts/get-details-for-product/${payload.productId}?routeMap=${routeMapId}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ACCEPTANCEACT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("acceptance-acts", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ACCEPTANCEACT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("acceptance-acts/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ACCEPTANCEACT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("acceptance-acts/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ACCEPTANCEACTS](state, acceptanceacts) {
    state.isLoadingAcceptanceActs = false;
    state.acceptanceacts = acceptanceacts;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingAcceptanceActs = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
