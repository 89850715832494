import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_RAW_PRODUCTS = "getRawProducts";
export const GET_ALL_RAW_PRODUCTS = "getAllRawProducts";
export const GET_RAW_PRODUCT = "getRawProduct";
export const SAVE_RAW_PRODUCT = "saveRawProduct";
export const UPDATE_RAW_PRODUCT = "updateRawProduct";
export const DELETE_RAW_PRODUCT = "deleteRawProduct";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingProduct";
export const SET_RAW_PRODUCTS = "setProducts";

const state = {
  errors: {},
  raw_products: [],
  isLoadingRawProducts: false,
};

const getters = {
  raw_products(state) {
    return state.raw_products;
  },
  isLoadingRawProducts() {
    return state.isLoadingRawProducts;
  },
};

const actions = {
  [GET_RAW_PRODUCTS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("raw-products/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_RAW_PRODUCTS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("raw-products")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_RAW_PRODUCT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("raw-products", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_RAW_PRODUCT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("raw-products", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_RAW_PRODUCT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("raw-products/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_RAW_PRODUCT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("raw-products/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((response) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_RAW_PRODUCTS](state, raw_products) {
    state.isLoadingRawProducts = false;
    state.raw_products = raw_products;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingRawProducts = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
