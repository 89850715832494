import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_STATS = "getStats";
export const GET_ALL_STATS = "getAllStats";
export const DOWNLOAD_STATS = "downloadStats";
export const GET_STAT = "getStat";
export const SAVE_STAT = "saveStat";
export const UPDATE_STAT = "updateStat";
export const DELETE_STAT = "deleteStat";
export const GET_NORMS_STATS = "getNormsStats";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingStat";
export const SET_STATS = "setStats";

const state = {
  errors: {},
  stats: [],
  isLoadingStats: false
};

const getters = {
  stats(state) {
    return state.stats;
  },
  isLoadingStats() {
    return state.isLoadingStats;
  }
};

const actions = {
  [GET_STATS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("stats/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_STATS](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get(
        "statistics/residual-quantity-in-stock?search=" + payload.search
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DOWNLOAD_STATS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("files/get/csv")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_STAT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("stats", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_STAT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("stats", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_STAT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("stats/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_STAT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("stats/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_NORMS_STATS](context, payload) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get(
        `statistics/norms?year=${payload.year}&month=${payload.month}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_STATS](state, stats) {
    state.isLoadingStats = false;
    state.stats = stats;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingStats = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
