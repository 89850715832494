import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./common/api.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

//
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";
// import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

// import { LOGOUT } from "@/store/auth.module";

import Datatable from "vue2-datatable-component";

//Import core functionality
import "./common/filters";

import VueCountdown from "@chenfengyuan/vue-countdown";
Vue.component(VueCountdown.name, VueCountdown);

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

// import Notifications from 'vue-notification';

// Vue.use(Notifications);
import vSelect from "vue-select";

Vue.component("vu-select", vSelect);

import "vue-select/dist/vue-select.css";

import VueLodash from "vue-lodash";
import lodash from "lodash";

import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

Vue.use(Datetime);
Vue.component("datetime", Datetime);

// name is optional
Vue.use(VueLodash, { name: "custom", lodash: lodash });

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

Vue.use(vue2Dropzone);

Vue.config.productionTip = false;
Vue.use(Antd);

Vue.use(Datatable); // done!

import VueGoogleCharts from "vue-google-charts";

Vue.use(VueGoogleCharts);

import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
Vue.use(Multiselect);
Vue.component("multiselect", Multiselect);

// API service init
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// window.addEventListener(
//     "storage",
//     function(e) {
//         if (e.storageArea.userData === undefined || e.key == "userData") {
//             store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
//         }
//     },
//     false
// );

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
