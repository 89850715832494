import Vue from "vue";
import Router from "vue-router";
import JwtService from "@/common/jwt.service";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      beforeEnter: (to, from, next) => {
        // check user permissions
        if (to.meta.roles) {
          if (!JwtService.getUser()) {
            next({ path: "/login" });
          } else if (
            JwtService.getUser().role == "Hall" &&
            to.name !== "hallRoutemapActions"
          ) {
            next({ path: "/hall" });
          } else if (!to.meta.roles.includes(JwtService.getUser().role)) {
            next({ path: "/dashboard" });
          } else {
            next();
          }
        } else {
          next();
        }
      },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          meta: {
            title: "Начало",
            roles: [
              "Admin",
              "Qualified",
              "Manager",
              "Labourer",
              "Quality Manager",
              "Hall"
            ]
          },
          component: () => import("@/views/pages/dashboard/Dashboard.vue")
        },
        // {
        //   path: "/request",
        //   name: "request",
        //   component: () => import("@/views/pages/request/Request.vue"),
        //   meta: {
        //     roles: ["Admin", "Qualified", "Manager"],
        //     title: ""
        //   }
        // },
        // {
        //   name: "table",
        //   path: "/table",
        //   component: () => import("@/views/pages/common/Table"),
        //   meta: {
        //     roles: ["Admin", "Qualified", "Manager"],
        //     title: ""
        //   }
        // },
        // {
        //   name: "operatorLogin",
        //   path: "/operator-login",
        //   component: () => import("@/views/pages/operator/Login"),
        //   meta: {
        //     roles: ["Hall"],
        //     title: "Оператори - дейност"
        //   }
        // },
        // {
        //   path: "/operator-routemap",
        //   component: () => import("@/views/pages/operatorRoutemap/Index"),
        //   meta: {
        //     roles: ["Hall"],
        //     title: "Маршрутна карта"
        //   },
        //   children: [
        //     {
        //       name: "operatorRoutemapList",
        //       path: "/",
        //       component: () => import("@/views/pages/operatorRoutemap/List"),
        //       meta: {
        //         roles: ["Hall"],
        //         title: "Маршрутна карта"
        //       }
        //     },
        //     {
        //       name: "operatorRoutemapCreateUpdate",
        //       path: "add/:id?",
        //       component: () =>
        //         import("@/views/pages/operatorRoutemap/CreateUpdate"),
        //       meta: {
        //         roles: ["Hall"],
        //         title: "Маршрутна карта"
        //       }
        //     }
        //   ]
        // },
        {
          path: "/products",
          component: () => import("@/views/pages/products/Index"),
          meta: {
            roles: ["Admin", "Manager"],
            title: "Продукти"
          },
          children: [
            {
              name: "productsList",
              path: "/",
              component: () => import("@/views/pages/products/List"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Продукти"
              }
            },
            {
              name: "productsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/products/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на продукт"
              }
            }
          ]
        },
        {
          path: "/raw-products",
          component: () => import("@/views/pages/raw_products/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager"],
            title: "Суровини"
          },
          children: [
            {
              name: "rawProductsList",
              path: "/",
              component: () => import("@/views/pages/raw_products/List"),
              meta: {
                roles: ["Admin", "Qualified", "Manager"],
                title: "Суровини"
              }
            },
            {
              name: "rawProductsCreateUpdate",
              path: "add/:id?",
              component: () =>
                import("@/views/pages/raw_products/CreateUpdate"),
              meta: {
                roles: ["Admin", "Qualified", "Manager"],
                title: "Редакция на суровина"
              }
            }
          ]
        },
        {
          path: "/materials",
          component: () => import("@/views/pages/materials/Index"),
          meta: {
            roles: ["Admin", "Quality Manager"],
            title: "Материали"
          },
          children: [
            {
              name: "materialsList",
              path: "/",
              component: () => import("@/views/pages/materials/List"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title: "Материали"
              }
            },
            {
              name: "materialsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/materials/CreateUpdate"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title: "Редакция на материал"
              }
            }
          ]
        },
        {
          path: "/material-properties",
          component: () => import("@/views/pages/material_properties/Index"),
          meta: {
            roles: ["Admin", "Quality Manager"],
            title: "Материали - изисквания"
          },
          children: [
            {
              name: "materialPropertiesList",
              path: "/",
              component: () => import("@/views/pages/material_properties/List"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title: "Материали - изисквания"
              }
            },
            {
              name: "materialPropertiesCreateUpdate",
              path: "add/:id?",
              component: () =>
                import("@/views/pages/material_properties/CreateUpdate"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title: "Редакция на изискване за материал"
              }
            }
          ]
        },
        {
          path: "/vendors",
          component: () => import("@/views/pages/vendors/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Quality Manager"],
            title: "Доставчици"
          },
          children: [
            {
              name: "vendorsList",
              path: "/",
              component: () => import("@/views/pages/vendors/List"),
              meta: {
                roles: ["Admin", "Qualified", "Quality Manager"],
                title: "Доставчици"
              }
            },
            {
              name: "vendorsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/vendors/CreateUpdate"),
              meta: {
                roles: ["Admin", "Qualified", "Quality Manager"],
                title: "Редакция на доставчик"
              }
            }
          ]
        },
        {
          path: "/printing-houses",
          component: () => import("@/views/pages/printingHouses/Index"),
          meta: {
            roles: ["Admin", "Quality Manager"],
            title: "Печатници"
          },
          children: [
            {
              name: "printingHousesList",
              path: "/",
              component: () => import("@/views/pages/printingHouses/List"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title: "Печатници"
              }
            },
            {
              name: "printingHousesCreateUpdate",
              path: "add/:id?",
              component: () =>
                import("@/views/pages/printingHouses/CreateUpdate"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title: "Редакция на печатници"
              }
            }
          ]
        },

        {
          path: "/clients",
          component: () => import("@/views/pages/clients/Index"),
          meta: {
            roles: ["Admin", "Manager"],
            title: "Клиенти"
          },
          children: [
            {
              name: "clientsList",
              path: "/",
              component: () => import("@/views/pages/clients/List"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Клиенти"
              }
            },
            {
              name: "clientsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/clients/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на клиент"
              }
            }
          ]
        },
        {
          path: "/operators",
          component: () => import("@/views/pages/operators/Index"),
          meta: {
            roles: ["Admin", "Manager"],
            title: "Оператори"
          },
          children: [
            {
              name: "operatorsList",
              path: "/",
              component: () => import("@/views/pages/operators/List"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Оператори"
              }
            },
            {
              name: "operatorsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/operators/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на оператор"
              }
            },
            {
              name: "operatorsHolidays",
              path: "holidays/:id",
              component: () => import("@/views/pages/operators/Holidays"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Почивни дни за оператор"
              }
            }
          ]
        },
        {
          path: "/warehouses",
          component: () => import("@/views/pages/warehouses/Index"),
          meta: {
            roles: ["Admin", "Manager"],
            title: "Складове"
          },
          children: [
            {
              name: "warehousesList",
              path: "/",
              component: () => import("@/views/pages/warehouses/List"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Складове"
              }
            },
            {
              name: "warehousesCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/warehouses/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на склад"
              }
            }
          ]
        },
        {
          path: "/users",
          component: () => import("@/views/pages/users/Index"),
          meta: {
            roles: ["Admin"],
            title: "Потребители"
          },
          children: [
            {
              name: "usersList",
              path: "/",
              component: () => import("@/views/pages/users/List"),
              meta: {
                roles: ["Admin"],
                title: "Потребители"
              }
            },
            {
              name: "usersCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/users/CreateUpdate"),
              meta: {
                roles: ["Admin"],
                title: "Редакция на потребител"
              }
            }
          ]
        },
        {
          path: "/profile",
          component: () => import("@/views/pages/users/Index"),
          meta: {
            roles: [
              "Admin",
              "Manager",
              "Hall",
              "Labourer",
              "Qualified",
              "Quality Manager"
            ],
            title: "Профил"
          },
          children: [
            {
              name: "editProfile",
              path: "/",
              component: () => import("@/views/pages/users/UpdateProfile"),
              meta: {
                roles: [
                  "Admin",
                  "Manager",
                  "Hall",
                  "Labourer",
                  "Qualified",
                  "Quality Manager"
                ],
                title: "Профил"
              }
            },
            {
              name: "productsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/products/CreateUpdate"),
              meta: {
                roles: ["Admin", "Qualified", "Manager"],
                title: "Редакция на продукт"
              }
            }
          ]
        },
        // {
        //   path: "/acceptance-acts",
        //   component: () => import("@/views/pages/acceptance_acts/Index"),
        //   meta: {
        //     roles: ["Admin", "Qualified", "Manager"],
        //     title: "Приемно-предавателен протокол"
        //   },
        //   children: [
        //     {
        //       name: "acceptanceActsList",
        //       path: "/",
        //       component: () => import("@/views/pages/acceptance_acts/List"),
        //       meta: {
        //         roles: ["Admin", "Qualified", "Manager"],
        //         title: "Приемно-предавателен протокол"
        //       }
        //     }
        //     // {
        //     //   name: "acceptanceActsCreateUpdate",
        //     //   path: "add/:id?",
        //     //   component: () =>
        //     //     import("@/views/pages/acceptance_acts/CreateUpdate"),
        //     //   meta: {
        //     //     roles: ["Admin", "Qualified", "Manager"],
        //     //     title: "Редакция на приемно-предавателен протокол"
        //     //   }
        //     // }
        //   ]
        // },

        {
          path: "/material-request",
          component: () => import("@/views/pages/material_request/Index"),
          meta: {
            roles: ["Admin", "Manager"],
            title: "Заявка за лекарствен продукт"
          },
          children: [
            {
              name: "materialRequestList",
              path: "/",
              component: () => import("@/views/pages/material_request/List"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Заявка за лекарствен продукт"
              }
            },
            {
              name: "materialRequestCreateUpdate",
              path: "add/:id?",
              component: () =>
                import("@/views/pages/material_request/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на заявка за лекарствен продукт"
              }
            }
          ]
        },
        {
          path: "/certificates",
          component: () => import("@/views/pages/certificates/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Quality Manager"],
            title: "Сертификат за освобождаване на опаковъчни материали"
          },
          children: [
            {
              name: "certificatesList",
              path: "/",
              component: () => import("@/views/pages/certificates/List"),
              meta: {
                roles: ["Admin", "Qualified", "Quality Manager", "Manager"],
                title: "Сертификат за освобождаване на опаковъчни материали"
              }
            },
            {
              name: "certificatesCreateUpdate",
              path: "add/:id?",
              component: () =>
                import("@/views/pages/certificates/CreateUpdate"),
              meta: {
                roles: ["Admin", "Qualified", "Quality Manager"],
                title:
                  "Редакция на сертификат за освобождаване на опаковъчни материали"
              }
            }
          ]
        },
        {
          path: "/destruction-packaging-materials",
          component: () =>
            import("@/views/pages/destruction_packaging_materials/Index"),
          meta: {
            roles: ["Admin", "Quality Manager"],
            title: "Протокол за унищожаване на вторични опаковъчни материали"
          },
          children: [
            {
              name: "destructionPackagingMaterialList",
              path: "/",
              component: () =>
                import("@/views/pages/destruction_packaging_materials/List"),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title:
                  "Протокол за унищожаване на вторични опаковъчни материали"
              }
            },
            {
              name: "destructionPackagingMaterialCreateUpdate",
              path: "add/:id?",
              component: () =>
                import(
                  "@/views/pages/destruction_packaging_materials/CreateUpdate"
                ),
              meta: {
                roles: ["Admin", "Quality Manager"],
                title:
                  "Редакция на протокол за унищожаване на вторични опаковъчни материали"
              }
            }
          ]
        },
        {
          path: "/invoices",
          component: () => import("@/views/pages/invoices/Index"),
          meta: {
            roles: ["Admin", "Manager", "Quality Manager"],
            title: "Фактури"
          },
          children: [
            {
              name: "invoicesList",
              path: "/",
              component: () => import("@/views/pages/invoices/List"),
              meta: {
                roles: ["Admin", "Manager", "Quality Manager"],
                title: "Фактури"
              }
            },
            {
              name: "invoicesCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/invoices/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager", "Quality Manager"],
                title: "Редакция на фактура"
              }
            }
          ]
        },
        {
          name: "statistics",
          path: "/statistics",
          component: () => import("@/views/pages/statistics/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
            title: "Статистика"
          },
          children: [
            {
              name: "quantityInStock",
              path: "quantity-in-stock",
              component: () =>
                import("@/views/pages/statistics/QuantityInStock"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Стока в наличност"
              }
            },
            {
              name: "normsStatistics",
              path: "norms",
              component: () => import("@/views/pages/statistics/Norms"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Норми"
              }
            }
          ]
        },
        {
          name: "settings",
          path: "/settings",
          component: () => import("@/views/pages/settings/Index"),
          meta: {
            roles: ["Admin"],
            title: "Настройки"
          },
          children: [
            {
              name: "workBreakList",
              path: "work-breaks",
              component: () => import("@/views/pages/settings/WorkBreakList"),
              meta: {
                roles: ["Admin"],
                title: "Почивки"
              }
            },
            {
              name: "workBreakCreateUpdate",
              path: "work-breaks/add/:id?",
              component: () =>
                import("@/views/pages/settings/WorkBreakCreateUpdate"),
              meta: {
                roles: ["Admin"],
                title: "Редактиране на почивка"
              }
            },
            {
              name: "workingTime",
              path: "working-time",
              component: () =>
                import("@/views/pages/settings/WorkingTimeUpdate"),
              meta: {
                roles: ["Admin"],
                title: "Работно време"
              }
            }
          ]
        },
        {
          path: "/scrap-register",
          component: () => import("@/views/pages/register/scrap/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
            title: "Регистър брак"
          },
          children: [
            {
              name: "scrapRegisterList",
              path: "/",
              component: () => import("@/views/pages/register/scrap/List"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Регистър брак"
              }
            }
          ]
        },
        {
          path: "/sample-register",
          component: () => import("@/views/pages/register/sample/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
            title: "Регистър мостри"
          },
          children: [
            {
              name: "sampleRegisterList",
              path: "/",
              component: () => import("@/views/pages/register/sample/List"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Регистър мостри"
              }
            }
          ]
        },
        {
          path: "/final-product-sale",
          component: () => import("@/views/pages/register/sample/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
            title: "Сертификат Освобождаване за продажба на краен продукт"
          },
          children: [
            {
              name: "finalProductSaleRegisterList",
              path: "/",
              component: () =>
                import("@/views/pages/register/final_product_sale/List"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Сертификат Освобождаване за продажба на краен продукт"
              }
            }
          ]
        },
        {
          path: "/emails",
          component: () => import("@/views/pages/emails/Index"),
          meta: {
            roles: ["Admin"],
            title: "Имейли"
          },
          children: [
            {
              name: "emailsList",
              path: "/",
              component: () => import("@/views/pages/emails/List"),
              meta: {
                roles: ["Admin"],
                title: "Имейли"
              }
            },
            {
              name: "emailsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/emails/CreateUpdate"),
              meta: {
                roles: ["Admin"],
                title: "Редакция на потребител"
              }
            }
          ]
        },
        {
          path: "/load",
          component: () => import("@/views/pages/load/Index"),
          meta: {
            roles: ["Labourer"],
            title: "Маршрутни карти за зареждане"
          },
          children: [
            {
              name: "loadList",
              path: "/",
              component: () => import("@/views/pages/load/List"),
              meta: {
                roles: ["Labourer"],
                title: "Маршрутни карти за зареждане"
              }
            }
          ]
        },
        {
          name: "logs",
          path: "/logs",
          component: () => import("@/views/pages/logs/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
            title: "Приемно-предавателен протокол"
          },
          children: [
            {
              name: "logsList",
              path: ":type/:id/:group?",
              component: () => import("@/views/pages/logs/List"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Приемно-предавателен протокол"
              }
            }
          ]
        },
        {
          name: "routemaps",
          path: "/routemaps",
          component: () => import("@/views/pages/routemaps/Index"),
          meta: {
            roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
            title: "Маршрутна карта"
          },
          children: [
            {
              name: "routemapsListNew",
              path: "new",
              component: () => import("@/views/pages/routemaps/ListNew"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Нови маршрутни карти"
              }
            },
            {
              name: "routemapsList",
              path: "list",
              component: () => import("@/views/pages/routemaps/List"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Текущи маршрутни карти"
              }
            },
            {
              name: "routemapsActions",
              path: "view/:id?",
              component: () => import("@/views/pages/routemaps/Actions"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Операции на маршрутна карта"
              }
            },
            {
              name: "routemapsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/routemaps/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на маршрутна карта"
              }
            },
            {
              name: "routemapsListDone",
              path: "done",
              component: () => import("@/views/pages/routemaps/ListDone"),
              meta: {
                roles: ["Admin", "Qualified", "Manager", "Quality Manager"],
                title: "Завършени маршрутни карти"
              }
            }
          ]
        },
        {
          path: "/halls",
          component: () => import("@/views/pages/hall/Index"),
          meta: {
            roles: ["Admin", "Manager"],
            title: "Зали"
          },
          children: [
            {
              name: "hallsList",
              path: "/",
              component: () => import("@/views/pages/hall/List"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Зали"
              }
            },
            {
              name: "hallsCreateUpdate",
              path: "add/:id?",
              component: () => import("@/views/pages/hall/CreateUpdate"),
              meta: {
                roles: ["Admin", "Manager"],
                title: "Редакция на зала"
              }
            }
          ]
        }
        // {
        //   name: "routemaps-actions",
        //   path: "/routemaps-actions",
        //   component: () => import("@/views/pages/routemaps/IndexActions"),
        //   meta: {
        //     roles: ["Admin", "Qualified", "Manager", "Hall"],
        //     title: "Операции и контрол на маршрутна карта"
        //   },
        //   children: [
        //     {
        //       name: "routemapsListOperations",
        //       path: "operation",
        //       component: () => import("@/views/pages/routemaps/ListOperations"),
        //       meta: {
        //         roles: ["Admin", "Qualified", "Manager", "Hall"],
        //         title: "Контрол на маршрутна карта"
        //       }
        //     },
        //     {
        //       name: "routemapsListControl",
        //       path: "control",
        //       component: () => import("@/views/pages/routemaps/ListControl"),
        //       meta: {
        //         roles: ["Admin", "Qualified", "Manager", "Hall"],
        //         title: "Операции на маршрутна карта"
        //       }
        //     },
        //     {
        //       name: "routemapsCreateUpdateOperations",
        //       path: "operation/:id",
        //       component: () =>
        //         import("@/views/pages/routemaps/CreateUpdateOperations"),
        //       meta: {
        //         roles: ["Admin", "Qualified", "Manager", "Hall"],
        //         title: "Операции на маршрутна карта"
        //       }
        //     },
        //     {
        //       name: "routemapsCreateUpdateControl",
        //       path: "control/:id",
        //       component: () =>
        //         import("@/views/pages/routemaps/CreateUpdateControl"),
        //       meta: {
        //         roles: ["Admin", "Qualified", "Manager", "Hall"],
        //         title: "Контрол на маршрутна карта"
        //       }
        //     }
        //   ]
        // }
      ]
    },
    {
      path: "/hall",
      component: () => import("@/views/pages/hall/Index"),
      beforeEnter: (to, from, next) => {
        // check user permissions
        if (to.meta.roles) {
          if (!JwtService.getUser()) {
            next({ path: "/login" });
          } else if (!to.meta.roles.includes(JwtService.getUser().role)) {
            next({ path: "/dashboard" });
          } else {
            next();
          }
        } else {
          next();
        }
      },
      meta: {
        roles: ["Hall"],
        title: "Операции на маршрутна карта"
      },
      children: [
        {
          name: "hallRoutemapActions",
          path: "/",
          component: () => import("@/views/pages/hall/RoutemapActions"),
          meta: {
            roles: ["Hall"],
            title: "Операции на маршрутна карта"
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          meta: {
            title: "Вход"
          },
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "twoFactor",
          path: "/login/2",
          component: () => import("@/views/pages/auth/TwoFactor"),
          meta: {
            title: "Двуфакторно удостоверяване"
          }
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register"),
          meta: {
            title: "Регистрация"
          }
        },
        {
          name: "forgot",
          path: "/forgot",
          component: () => import("@/views/pages/auth/Forgot"),
          meta: {
            title: "Забравена парола"
          }
        },
        {
          name: "reset",
          path: "/reset/:token/:email",
          component: () => import("@/views/pages/auth/Reset"),
          meta: {
            title: "Нулиране на парола"
          }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => {
        return import("@/views/pages/error/Error-1.vue");
      }
    }
  ]
});
