import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ALL_SAMPLE_REGISTER = "getAllSampleRegister";
export const GENERATE_PROTOCOL = "generateProtocol";
export const UPDATE_PROTOCOL = "updateProtocol";
export const DOWNLOAD_SAMPLE_REGISTER = "downloadSampleRegister";
//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingSampleRegister";
export const SET_SAMPLE_REGISTER = "setSampleRegister";

const state = {
  errors: {},
  sampleRegisters: [],
  isLoadingSampleRegister: false
};

const getters = {
  sampleRegisters(state) {
    return state.sampleRegisters;
  },
  isLoadingSampleRegister() {
    return state.isLoadingSampleRegister;
  }
};

const actions = {
  [GET_ALL_SAMPLE_REGISTER](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("sample/data", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GENERATE_PROTOCOL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("sample/generate-protocol", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PROTOCOL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(`sample/${payload.payload.id}`, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DOWNLOAD_SAMPLE_REGISTER](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("files/get/pdf")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SAMPLE_REGISTER](state, sampleRegisters) {
    state.isLoadingSampleRegister = false;
    state.sampleRegisters = sampleRegisters;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingSampleRegister = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
