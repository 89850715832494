import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ALL_PRODUCTS = "getAllProducts";
export const GET_PRODUCTS = "getProducts";
export const GET_PRODUCT = "getProduct";
export const SAVE_PRODUCT = "saveProduct";
export const UPDATE_PRODUCT = "updateProduct";
export const DELETE_PRODUCT = "deleteProduct";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingProduct";
export const SET_PRODUCTS = "setProducts";

const state = {
    errors: {},
    products: [],
    isLoadingProducts: false
};

const getters = {
    products(state) {
        return state.products;
    },
    isLoadingProducts() {
        return state.isLoadingProducts;
    }
};

const actions = {
    [GET_PRODUCTS](context, payload = "") {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.post("products/data", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_PRODUCT](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("products", id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [GET_ALL_PRODUCTS](context) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.get("products")
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [SAVE_PRODUCT](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.post("products", payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [UPDATE_PRODUCT](context, payload) {
        context.commit(SET_LOADING, true);
        return new Promise((resolve, reject) => {
            ApiService.put("products/" + payload.payload.id, payload.payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    },
    [DELETE_PRODUCT](context, id) {
        context.commit(SET_LOADING, true);

        return new Promise((resolve, reject) => {
            ApiService.delete("products/" + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                })
                .finally(() => {
                    context.commit(SET_LOADING, false);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_PRODUCTS](state, products) {
        state.isLoadingProducts = false;
        state.products = products;
        state.errors = {};
    },
    [SET_LOADING](state, value) {
        state.isLoadingProducts = value;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};