import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_PRODUCTOPERATIONS = "getProductOperations";
export const GET_PRODUCTOPERATION = "getProductOperation";
export const SAVE_PRODUCTOPERATION = "saveProductOperation";
export const UPDATE_PRODUCTOPERATION = "updateProductOperation";
export const DELETE_PRODUCTOPERATION = "deleteProductOperation";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingProductOperation";
export const SET_PRODUCTOPERATIONS = "setProductOperations";

const state = {
  errors: {},
  productoperations: [],
  isLoadingProductOperations: false
};

const getters = {
  productoperations(state) {
    return state.productoperations;
  },
  isLoadingProductOperations() {
    return state.isLoadingProductOperations;
  }
};

const actions = {
  [GET_PRODUCTOPERATIONS](context, id = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("product-operations?productId=" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_PRODUCTOPERATION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("product-operations", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_PRODUCTOPERATION](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("product-operations", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_PRODUCTOPERATION](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(
        "product-operations/" + payload.payload.id,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_PRODUCTOPERATION](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("product-operations/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_PRODUCTOPERATIONS](state, productoperations) {
    state.isLoadingProductOperations = false;
    state.productoperations = productoperations;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingProductOperations = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
