import ApiService from "@/common/api.service";

// action types
export const GET_COMPANY_CONTACTS = "getCompanyContacts";

// mutation types
export const SET_COMPANY_CONTACTS = "setCompanyContacts";
export const SET_ERROR = "setError";

const state = {
  errors: {},
  companyContacts: []
};

const getters = {
  companyContacts(state) {
    return state.companyContacts;
  }
};

const actions = {
  [GET_COMPANY_CONTACTS](context) {
    return new Promise(resolve => {
      ApiService.get("companyContacts")
        .then(({ data }) => {
          context.commit(SET_COMPANY_CONTACTS, data);
          resolve(data);
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_COMPANY_CONTACTS](state, companyContacts) {
    state.companyContacts = companyContacts;
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
