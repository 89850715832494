import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import companyContact from "./companyContact.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import users from "./users.module";
import products from "./products.module";
import rawProducts from "./rawProducts.module";
import materials from "./materials.module";
import vendors from "./vendors.module";
import warehouses from "./warehouses.module";
import formselect from "./formselect.module";
import materialProperties from "./materialProperties.module";
import productFormulas from "./productFormulas.module";
import productOperations from "./productOperations.module";
import acceptanceActs from "./acceptanceActs.module";
import clients from "./clients.module";
import operators from "./operators.module";
import files from "./files.module";
import certificates from "./certificates.module";
import certificateTypes from "./certificateTypes.module";
import certificateReanalysisRequests from "./certificateReanalysisRequests.module";
import routemaps from "./routemaps.module";
import routemapsOperations from "./routemapsOperations.module";
import routemapsControls from "./routemapsControls.module";
import operatorsData from "./operatorsData.module";
import printingHouses from "./printingHouses.module";
import statistics from "./statistics.module";
import invoices from "./invoices.module";
import workBreaks from "./workBreaks.module";
import workingTime from "./workingTime.module";
import materialRequest from "./materialRequest.module";
import logs from "./logs.module";
import destructionPackagingMaterials from "./destructionPackagingMaterials.module";
import halls from "./halls.module";
import emails from "./emails.module";
import scrapRegister from "./scrapRegister.module";
import sampleRegister from "./sampleRegister.module";
import finalProductSaleRegister from "./finalProductSaleRegister.module";
import profile from "./profile.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    logs,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    companyContact,
    users,
    products,
    productFormulas,
    productOperations,
    acceptanceActs,
    rawProducts,
    vendors,
    warehouses,
    materials,
    materialProperties,
    operators,
    clients,
    files,
    certificates,
    certificateTypes,
    certificateReanalysisRequests,
    routemaps,
    routemapsOperations,
    routemapsControls,
    printingHouses,
    operatorsData,
    formselect,
    statistics,
    invoices,
    workBreaks,
    workingTime,
    materialRequest,
    destructionPackagingMaterials,
    halls,
    emails,
    scrapRegister,
    sampleRegister,
    finalProductSaleRegister,
    profile
  }
});
