import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_ROUTEMAPS_CONTROLS_DATA = "getRoutemapsControlsData";
export const GET_ALL_ROUTEMAPS_CONTROLS = "getAllRoutemapsControls";
export const GET_ROUTEMAPS_CONTROLS = "getRoutemapsControls";
export const SAVE_ROUTEMAPS_CONTROLS = "saveRoutemapsControls";
export const GET_ROUTEMAPS_CONTROL_CODE = "getRoutemapsControlCode";
export const SAVE_ROUTEMAPS_MANAGER_CONTROL = "saveRoutemapsManagerControl";
export const UPDATE_ROUTEMAPS_CONTROLS = "updateRoutemapsControls";
export const DELETE_ROUTEMAPS_CONTROLS = "deleteRoutemapsControls";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingRoutemapsControls";
export const SET_ROUTEMAPS_CONTROLS = "setRoutemapsControls";

const state = {
  errors: {},
  routemaps_controls: [],
  isLoadingRoutemapsControls: false
};

const getters = {
  routemaps_controls(state) {
    return state.routemaps_controls;
  },
  isLoadingRoutemapsControls() {
    return state.isLoadingRoutemapsControls;
  }
};

const actions = {
  [GET_ROUTEMAPS_CONTROLS_DATA](context, payload = "") {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-controls/data", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ALL_ROUTEMAPS_CONTROLS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps-controls")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAPS_CONTROLS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("route-maps-controls", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROUTEMAPS_CONTROLS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-controls", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_ROUTEMAPS_CONTROL_CODE](context, routemapId) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-hall-control-code/" + routemapId)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_ROUTEMAPS_MANAGER_CONTROL](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("route-maps-manager-control", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_ROUTEMAPS_CONTROLS](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put(
        "route-maps-controls/" + payload.payload.id,
        payload.payload
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_ROUTEMAPS_CONTROLS](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("route-maps-controls/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ROUTEMAPS_CONTROLS](state, routemaps_controls) {
    state.isLoadingRoutemapsControls = false;
    state.routemaps_controls = routemaps_controls;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingRoutemapsControls = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
