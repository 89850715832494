import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { OauthClient } from "@/common/config";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const REFRESH_TOKEN = "refreshToken";
export const CHECK_TOKEN = "checkToken";
export const FORGOT = "forgot";
export const RESET = "reset";
export const TWOFACTOR = "twoFactor";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

export const PASSWORD_GRANT = "password";
export const REFRESH_TOKEN_GRANT = "refresh_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

const state = {
  errors: [],
  user: JwtService.getUser(),
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      const postData = new OauthClient(PASSWORD_GRANT, credentials);
      postData.remember = JwtService.getRemember();

      ApiService.post("login", postData)
        .then(({ data }) => {
          if (data.access_token) {
            context.commit(SET_AUTH, data);
            JwtService.saveTokens(data).then(() => {
              resolve(data);
            });
          } else {
            window.location.href = "login/2";
            resolve(data);
          }
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            response.data.error_description = response.data.error;
          }
          context.commit(SET_ERROR, response.data.error_description);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.post("logout")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
        })
        .finally(() => {
          context.commit(PURGE_AUTH);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error_description);
          reject(response);
        });
    });
  },

  [FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("forgot", { email: credentials })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            response.data.error_description = "Имейлът не е намерен!";
          }
          context.commit(SET_ERROR, response.data.error_description);
          reject(response);
        });
    });
  },
  [RESET](context, payload) {
    return new Promise((resolve, reject) => {
      const { email, password, password_confirmation, token } = payload;
      const form = { email, password, password_confirmation, token };
      ApiService.post("reset", form)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            response.data.error_description = response.data.error;
          }
          context.commit(SET_ERROR, response.data.error_description);
          reject(response);
        });
    });
  },
  [TWOFACTOR](context, payload) {
    const { code } = payload;
    return new Promise((resolve, reject) => {
      ApiService.get("two-factor", code)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          JwtService.saveTokens(data).then(() => {
            resolve(data);
          });
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            response.data.error_description = response.data.error;
          }
          context.commit(SET_ERROR, response.data.error_description);
          reject(response);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    return new Promise((resolve, reject) => {
      var oauthClientParams = {};
      oauthClientParams[REFRESH_TOKEN_KEY] = JwtService.getRefreshToken();
      const postData = new OauthClient(REFRESH_TOKEN_GRANT, oauthClientParams);

      ApiService.post("oauth/token", postData)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error_description);
          reject(response);
        });
    });
  },
  [CHECK_TOKEN](context) {
    return new Promise((resolve, reject) => {
      if (!state.isAuthenticated) {
        resolve(state.user);
      } else {
        actions[REFRESH_TOKEN](context)
          .then(data => {
            resolve(data);
          })
          .catch(response => {
            actions[LOGOUT](context);
            context.commit(SET_ERROR, response.data.error_description);
            reject(response);
          });
      }
    });
  }
};

const mutations = {
  [SET_ERROR](state, errors) {
    if (Array.isArray(errors)) {
      state.errors = errors;
    } else {
      state.errors = [errors];
    }
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.userData;
    state.errors = [];
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyTokens();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
