import ApiService from "@/common/api.service";

// action types
//Actions
export const GET_CLIENTS = "getClients";
export const GET_CLIENT = "getClient";
export const SAVE_CLIENT = "saveClient";
export const UPDATE_CLIENT = "updateClient";
export const DELETE_CLIENT = "deleteClient";
export const GET_ALL_CLIENTS = "getAllClients";

//Mutations
export const SET_ERROR = "setError";
export const SET_LOADING = "setLoadingClient";
export const SET_CLIENTS = "setClients";

const state = {
  errors: {},
  clients: [],
  isLoadingClients: false
};

const getters = {
  clients(state) {
    return state.clients;
  },
  isLoadingClients() {
    return state.isLoadingClients;
  }
};

const actions = {
  [GET_ALL_CLIENTS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
        ApiService.get("clients")
            .then(({ data }) => {
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            })
            .finally(() => {
                context.commit(SET_LOADING, false);
            });
    });
},
  [GET_CLIENTS](context) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("clients")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [GET_CLIENT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.get("clients", id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [SAVE_CLIENT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.post("clients", payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [UPDATE_CLIENT](context, payload) {
    context.commit(SET_LOADING, true);
    return new Promise((resolve, reject) => {
      ApiService.put("clients/" + payload.payload.id, payload.payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  },
  [DELETE_CLIENT](context, id) {
    context.commit(SET_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.delete("clients/" + id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        })
        .finally(() => {
          context.commit(SET_LOADING, false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CLIENTS](state, clients) {
    state.isLoadingClients = false;
    state.clients = clients;
    state.errors = {};
  },
  [SET_LOADING](state, value) {
    state.isLoadingClients = value;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
